import { toast } from "react-toastify";
import { store } from "../store";
import {
  IChatTaxonomies,
  IMessages,
} from "../store/conversationalSearch/conversationalSearch.interface";
import {
  setChatHistory,
  setChatHistoryLoading,
  setDeleteChatLoading,
  setIsExportingChatHistory,
  setIsThumbnailLoading,
  setSaveTitleLoading,
  setThumbnailsByPage,
} from "../store/conversationalSearch/conversationalSearchSlice";
import { deleteRequest, get, post, put } from "./apiClients";
import { onDownloadPdf, uniqueId } from "../shared";

// export interface IChatHistory {
//   id: number;
//   title: string | null;
//   messages?: IMessages[];
//   userId: number;
//   modelName: string;
//   predicted_next_questions?: string[];
//   createdOn: string;
//   chatTaxonomies?: any;
//   message?: string | null | undefined;
// }

interface IChatHistoryRequest {
  userId: number;
  configSettingId: number;
}

interface IMessageCommentRequest {
  chatId: number;
  answerId: string;
  userComments: string;
  thumbsUp: boolean;
  thumbsDown: boolean;
}

interface IEditChatTitleRequest {
  id: number;
  title: string;
}

interface IGetThumbnailsByPage {
  page_no: number;
  file_name: string;
}
export interface IChatHistory {
  id: number | null;
  title: string;
  messages: IMessages[];
  userId: number;
  modelName: string;
  createdOn: string;
  predicted_next_questions?: string[];
  chatTaxonomies?: IChatTaxonomies;
  language: string;
  resumeFinder: boolean;
}

export const saveStreamChatReply = async (request: IChatHistory) => {
  const res = await post("core/chat/saveStreamChat", request);
  return res;
};

export const getAllChatHistory = async (request: IChatHistoryRequest) => {
  try {
    store.dispatch(setChatHistoryLoading(true));

    const res = await post("core/chat/history", request);
    if (res?.data && Array.isArray(res.data)) {
      store.dispatch(setChatHistory(res.data));
    } else {
      store.dispatch(setChatHistory([]));
    }
  } catch (error) {
    console.log("Error while fetching chat history", error);
  } finally {
    store.dispatch(setChatHistoryLoading(false));
  }
};

export const messageComment = async (request: IMessageCommentRequest) => {
  const res = await put("core/chat/updateComment", request);
  return res;
};

export const deleteChatHistory = async (id: number) => {
  try {
    store.dispatch(setDeleteChatLoading(true));

    const res = await deleteRequest(`core/chat/${id}`);
    // store.dispatch(updateChat({ id: id }));
    toast.success("History deleted successfully");

    return res;
  } catch (error: any) {
    console.log("Error while fetching chat history", error?.message);
    toast.error(error?.message || "Failed to delete history");
  } finally {
    store.dispatch(setDeleteChatLoading(false));
  }
};

export const editChatTitle = async (request: IEditChatTitleRequest) => {
  try {
    store.dispatch(setSaveTitleLoading(true));

    const res = await put("core/chat/editTitle", request);
    // store.dispatch(updateTitle({ id: request.id, title: request.title }));

    toast.success("Title renamed successfully");
    return res;
  } catch (error: any) {
    console.log("Error while fetching chat history", error.message);
    toast.error(error?.message || "Failed to rename title");
  } finally {
    store.dispatch(setSaveTitleLoading(false));
  }
};

export const getThumbnailsByPage = async (
  selectedChatHistoryId: number,
  request: IGetThumbnailsByPage[]
) => {
  try {
    store.dispatch(setIsThumbnailLoading(true));

    const res = await post("core/asset/getThumbnailByPage", request);
    if (res?.data)
      store.dispatch(
        setThumbnailsByPage({ selectedChatHistoryId: selectedChatHistoryId, thumbnails: res?.data })
      );
    return res.data;
  } catch (error) {
    console.log("Error while fetching Thumbnail", error);
  } finally {
    store.dispatch(setIsThumbnailLoading(false));
  }
};

export const getAdminLogsData = async (startDate: string, endDate: string) => {
  try {
    store.dispatch(setIsExportingChatHistory(true));
    const res = await get(`/core/chat/chatHistoryReport?startDate=${startDate}&endDate=${endDate}`);
    onDownloadPdf(res, null, `Report-${uniqueId()}`);
    toast.success("Logs successfully exported");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to export Logs. Please try again");
    return error;
  } finally {
    store.dispatch(setIsExportingChatHistory(false));
  }
};

export const getPDFPreSignedUrl = async (file_name: string) => {
  try {
    const res = await get(`/core/asset/pdfPreSignedUrl?file_name=${file_name}`);
    return res;
  } catch (error: any) {
    console.log("error", error);
    toast.error(error?.errorMessage ?? "Failed to get file url. Please try again");
    return error;
  }
};
