import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useRef, useState } from "react";
import { Card, Carousel, Popover, Tooltip } from "antd";
import "./HomeSlider.scss";
import { RootState } from "../../../store";
import { FileCardSkeleton } from "../../fileCard/fileCardSkeleton";
import ContentChatButton from "../../contentChatButton/ContentChatButton";
import { IContent } from "../../../store/contentManagement/content.interface";
import { IHomeBookmarkedContent } from "../../../store/home/home.interface";
import {
  setHomeBookmarkedContent,
  setOthersLookingAssets,
  setRecentlyViewedAssets,
} from "../../../store/home/homeSlice";
import { bookmarkContent, updateViewCount } from "../../../services/contentManagement";
import {
  AssetSummaryPopover,
  BookmarkIcon,
  FromWhere,
  getCardOwnersFullName,
  getFormattedDate,
  getIsContentFailed,
  getIsDisableEditContent,
  handleOpenPdfDoc,
  Images,
  isEmpty,
  isNew,
  NoContentData,
  PATHS,
  renderFileTypeIcon,
} from "../../../shared";
import {
  setImageURL,
  setIsImageVisible,
  setPDFContent,
} from "../../../store/pdfViewer/pdfViewer.slice";
import {
  contentByIdInitialValue,
  contentFilterField,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateSourceFilter,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";

interface IHomeSlider {
  isLoading?: boolean;
  title: string;
  description?: boolean;
  data: IContent[];
  settings?: any;
  fromWhere?: string;
}

const HomeSlider: FC<IHomeSlider> = ({
  isLoading,
  title,
  description = false,
  data,
  settings,
  fromWhere = "",
}) => {
  const [showCount, setShowCount] = useState<any>(5.3);
  const [elementRect, setElementRect] = useState<number>(0);

  const { appLayoutView } = useSelector((state: RootState) => state.app);
  const { contents } = useSelector((state: RootState) => state.content);
  const { homeBookmarkedContent } = useSelector((state: RootState) => state.home);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref?.current) {
      const { width } = ref?.current?.getBoundingClientRect();
      setElementRect(width + 21);
      const num = elementRect / 230;
      setShowCount(+num.toFixed(2));
    }
  }, [elementRect, appLayoutView]);

  const sliderPrevBtn = () => {
    return (
      <button type="button">
        <i className="ri-arrow-left-s-line"></i>
      </button>
    );
  };

  const sliderNextBtn = () => {
    return (
      <button type="button">
        <i className="ri-arrow-right-s-line"></i>
      </button>
    );
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 250,
    slidesToShow: showCount,
    slidesToScroll: 1,
    prevArrow: sliderPrevBtn(),
    nextArrow: sliderNextBtn(),
    ...settings,
  };

  const noClick = (e: any) => {
    e.stopPropagation();
  };

  const handleUpdatedViewedList = (content: IContent, index: number, isBookmarked: boolean) => {
    const updatedContent = {
      ...content,
      is_bookmarked: isBookmarked,
    };
    const contentsValues = [...data];
    contentsValues[index] = updatedContent;

    if (fromWhere === FromWhere.RecentlyViewed) {
      dispatch(setRecentlyViewedAssets(contentsValues));
    } else if (fromWhere === FromWhere.OthersViewed) {
      dispatch(setOthersLookingAssets(contentsValues));
    }
  };

  const handleUpdateHomeBookmark = (content: IContent, isBookmarked: boolean) => {
    const updatedContent = {
      ...content,
      is_bookmarked: isBookmarked,
    };
    const values = [...homeBookmarkedContent];
    let updatedBookmark: IHomeBookmarkedContent[] = [];

    if (!isEmpty(values)) {
      values.forEach((item) => {
        if (item?.asset_id === updatedContent.asset_id) {
          updatedBookmark.push(updatedContent);
        } else {
          updatedBookmark.push(item);
        }
      });
      updatedBookmark.push(updatedContent);
    } else {
      updatedBookmark.push(updatedContent);
    }

    updatedBookmark = updatedBookmark.filter((item) => item.is_bookmarked);
    dispatch(setHomeBookmarkedContent(updatedBookmark));
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const isBookmarked = content?.is_bookmarked ? false : true;
    const res = await bookmarkContent(content.asset_id, isBookmarked);
    if (res) {
      handleUpdatedViewedList(content, index, isBookmarked);
      handleUpdateHomeBookmark(content, isBookmarked);
    }
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(setUpdateSourceFilter({ field: contentFilterField?.currentPage, value: 1 }));
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: content ? getIsContentFailed(content) : false,
        fromWhere: FromWhere.Source,
      },
    });
  };

  const handlePDFClick = (item: IContent) => {
    dispatch(setPDFContent(item));
    if (item?.file_type === null) {
      toast.error("URL can't be viewed.");
    } else if (Images.includes(item?.file_type || "")) {
      dispatch(setIsImageVisible(true));
      dispatch(setImageURL(item?.thumbnail_pre_signed_url ?? ""));
    } else if (item?.file_type === "xls" || item?.file_type === "xlsx") {
      handleViewEditContentNavigate(item?.asset_id, false);
    } else {
      handleOpenPdfDoc(
        item?.asset_id,
        item.file_name,
        item?.pre_signed_url,
        item?.title,
        item.total_pages,
        false,
        true
      );
    }
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const chatOption = (content: IContent) => {
    return isLoading || getIsDisableEditContent(content) ? (
      <></>
    ) : (
      <Tooltip
        placement="topRight"
        trigger="hover"
        title="Chat with this document"
        rootClassName="start-chat-tooltip">
        <ContentChatButton {...{ content, isVisible: true }} />
      </Tooltip>
    );
  };

  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        {chatOption(content)}
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const renderContentCard = (item: IContent, index: number) => {
    return (
      <Card className="file-card cursor-pointer variant" onClick={() => handlePDFClick(item)}>
        <div className="file-preview">
          <div
            className="file-bg"
            style={{ backgroundImage: `url(${item?.thumbnail_pre_signed_url})` }}></div>
          <div className="file-prev-inner">
            <div className="thumb-labels">
              {isNew(item?.uploadedOn) && <div className="thumbnail-label">Newly added</div>}
            </div>
            <div className="extra-options" onClick={noClick}>
              {fileExtraOptionsTwo(item, index)}
            </div>
            <div className="file-icon">
              <img src={renderFileTypeIcon(item?.file_type)} alt="Icon" />
            </div>
          </div>
        </div>
        <div className="file-info">
          <div className="file-name-info d-flex d-flex-middle">
            <h6 className="file-name">{item?.title ? item?.title : item?.file_name}</h6>
          </div>

          <div className="file-data">
            {renderOwnerUI(item)}
            <p className="file-date">{getFormattedDate(item?.modifiedOn, "type1")}</p>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Card className="slider-card-wrapper">
        <h2 className="wrapper-title">{title}</h2>
        {!isEmpty(data) && description ? (
          <></>
        ) : (
          // <p className="wrapper-subText">
          //   Because last you had viewed <strong>{data[0]?.title || data[0]?.file_name}</strong> file.
          // </p>
          ""
        )}
        <div className="slider-wrapper" ref={ref}>
          {isLoading ? (
            <div className="slider-skeleton">
              <FileCardSkeleton />
            </div>
          ) : !isEmpty(data) ? (
            <div className="slider-inner-wrapper">
              <Carousel {...sliderSettings}>
                {data.map((item: IContent, index: number) => (
                  <Popover
                    key={`${item?.asset_id}-${index}`}
                    placement="bottomLeft"
                    rootClassName="custom-description-root"
                    content={
                      <AssetSummaryPopover
                        content={item}
                        contentSource={true}
                        fromWhere={fromWhere}
                      />
                    }>
                    {renderContentCard(item, index)}
                  </Popover>
                ))}
              </Carousel>
            </div>
          ) : (
            <NoContentData />
          )}
        </div>
      </Card>
    </>
  );
};

export default HomeSlider;
