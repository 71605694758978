import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Checkbox, Col, Image, Row, Table } from "antd";
import { toast } from "react-toastify";
import { AssetFileCard, ContentChatButton } from "../../components";
import ViewEditContentManagement from "../viewEditContentManagement/ViewEditContentManagement";
import { RootState } from "../../store";
import { setPdfDetails } from "../../store/pdfViewer/pdfViewer.slice";
import {
  contentByIdInitialValue,
  setContentById,
  setRelevantAssetView,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
} from "../../store/contentManagement/contentSlice";
import {
  ICitations,
  IThumbnails,
} from "../../store/conversationalSearch/conversationalSearch.interface";
import {
  setIsChatStartWithAssetFilter,
  setSelectedRelevantAssetMsgId,
  setRelevantAssetViewType,
  setSelectedMessageCitations,
  setSelectedReleventAsset,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { getThumbnailsByPage } from "../../services/conversationalSearch";
import { updateViewCount } from "../../services/contentManagement";
import {
  FileCardType,
  FromWhere,
  Images,
  isEmpty,
  PATHS,
  PDFViewer,
  RelevantAssetView,
  renderFileTypeIcon,
  uniqueId,
  ViewType,
} from "../../shared";
import "./RelevantAssets.scss";

const RelevantAssets = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { routeHistory } = useSelector((state: RootState) => state.app);
  const { pdfDetails } = useSelector((state: RootState) => state.pdfViewer);
  const { relevantAssetView } = useSelector((state: RootState) => state.content);
  const { selectedChatHistory, selectedMessageCitations, relevantAssetViewType } = useSelector(
    (state: RootState) => state.conversationalSearch
  );

  const [selectedAssets, setSelectedAssets] = useState<ICitations[]>([]);
  const [citationList, setCitationList] = useState<ICitations[]>([]);
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);
  // const [currentChatId, setCurrentChatId] = useState(selectedChatHistory.id);
  const [isThumnailUpdated, setIsThumbnailUpdated] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setSelectedReleventAsset([]));
      // dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setIsChatStartWithAssetFilter(false));
      setSelectedAssets([]);
      // setCurrentChatId(-1);
    };
  }, []);

  // useEffect(() => {
  //   if (currentChatId === -1 || (!selectedMessageCitations?.length && !citationList.length)) {
  //     let redirectPath = routeHistory[1] || PATHS.home;
  //     console.warn("redirectPath  ===", redirectPath);
  //     if (redirectPath === PATHS.relevantAssets)
  //       redirectPath = routeHistory?.find((p: string) => p != PATHS.relevantAssets) || PATHS.home;
  //     navigate(redirectPath);
  //   }
  // }, [selectedChatHistory]);

  useEffect(() => {
    const fromWhere = location?.state?.fromWhere || "";
    if (selectedMessageCitations?.length > 0 && fromWhere === FromWhere.ConversationalSearch) {
      dispatch(setSelectedMessageCitations([]));
      const filteredCitations = selectedMessageCitations.filter((c) => c.asset_level === "primary");

      if (
        isEmpty(citationList) ||
        (!isEmpty(citationList) && citationList[0]?.asset_id !== filteredCitations[0]?.asset_id)
      ) {
        setCitationList(filteredCitations);
        setIsThumbnailUpdated(false);
        dispatch(setRelevantAssetView(RelevantAssetView.None));
        dispatch(
          setPdfDetails({
            assetId: -1,
            fileName: "",
            presignedUrl: "",
            title: "",
            page: 0,
            showOpenIcon: false,
          })
        );
      }
      if (pdfDetails.assetId === -1) dispatch(setRelevantAssetView(RelevantAssetView.None));
      // const groupedData = groupItemsByFileName(filteredCitations);
      // setListViewRecords(groupedData);
    }
  }, [selectedMessageCitations, location?.state]);

  const isImage = (item: ICitations) => Images.includes(item?.file_type || "");

  const toggleCardCollapseForCitation = (pdfDetails: ICitations) => {
    if (["xls", "xlsx"].includes(pdfDetails?.file_type)) {
      toast.error("Excel file preview not supported");
      return;
    }

    if (isImage(pdfDetails)) {
      setVisible(true);
      setSelectedImageURL(pdfDetails.pre_signed_url);
    } else {
      document.body.classList.add("relevant-assets-page"); // Toggle the class on body tag
      dispatch(setRelevantAssetView(RelevantAssetView.PDF));
      dispatch(
        setPdfDetails({
          assetId: pdfDetails?.asset_id,
          fileName: pdfDetails?.file_name,
          presignedUrl: pdfDetails?.pre_signed_url || "",
          title: pdfDetails?.title,
          page: pdfDetails.page || 0,
          showOpenIcon: false,
        })
      );
    }
  };

  const getIsPreSignedUrlEmpty = (citationList: ICitations[]) =>
    citationList.filter((item) => isEmpty(item?.pre_signed_url))?.length ? true : false;

  useEffect(() => {
    if (citationList?.length > 0 && selectedChatHistory?.id !== -1 && !isThumnailUpdated) {
      setIsThumbnailUpdated(true);
      const request = citationList
        ?.filter((c) => c.asset_level === "primary")
        ?.map((citation) => {
          return {
            file_name: citation.file_name,
            page_no: citation?.page ?? null,
          };
        });

      if (!isEmpty(citationList) && getIsPreSignedUrlEmpty(citationList)) {
        getThumbnailsByPage(selectedChatHistory.id, request).then((thumbnails: IThumbnails[]) => {
          const updatedCitations = citationList.map((citation) => {
            const findThumbnail = thumbnails?.find(
              (thumbnail) =>
                thumbnail.file_name === citation.file_name && thumbnail.page_no === citation.page
            );
            if (findThumbnail?.pre_signed_url) {
              return { ...citation, pre_signed_url: findThumbnail.pre_signed_url };
            }
            return citation;
          });
          setCitationList(updatedCitations);
        });
      }
    }
  }, [citationList, selectedChatHistory?.id]);

  const getSelectedAssets = () => {
    return selectedAssets.reduce((acc: ICitations[], current: ICitations) => {
      if (!acc.some((item: ICitations) => item.asset_id === current.asset_id)) {
        acc.push(current);
      }
      return acc;
    }, []);
  };

  // const handleChatWithAssetFilter = () => {
  //   const uniqAssets: ICitations[] = selectedAssets.reduce(
  //     (acc: ICitations[], current: ICitations) => {
  //       if (!acc.some((item: ICitations) => item.asset_id === current.asset_id)) {
  //         acc.push(current);
  //       }
  //       return acc;
  //     },
  //     []
  //   );
  //   dispatch(setSelectedReleventAsset(uniqAssets));
  //   dispatch(setSelectedFilesForAssetFilter(selectedAssets));
  //   dispatch(setIsChatStartWithAssetFilter(false));
  //   // setSelectedAssets([]);
  // };

  const handleSetCheckedAssets = (e: CheckboxChangeEvent, data: ICitations) => {
    let prevSelectedAssets = [...selectedAssets];
    let ciationValues = [...citationList];
    const filteredCiation = ciationValues?.filter((item) => item.asset_id === data.asset_id);

    if (e.target.checked) {
      setSelectedAssets([...prevSelectedAssets, ...filteredCiation]);
    } else {
      setSelectedAssets(prevSelectedAssets.filter((e) => e.asset_id !== data.asset_id));
    }
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(setSelectedMessageCitations(citationList));
    updateViewCount(assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: true,
        fromWhere: FromWhere.ConversationalSearch,
      },
    });
  };

  const renderAssetView = () => {
    switch (relevantAssetView) {
      case RelevantAssetView.PDF:
        return (
          <div>
            <PDFViewer
              isRelevantAsset={true}
              isShowCustomCloseIcon={true}
              handleNavigateContentById={handleViewEditContentNavigate}
              fromWhere={FromWhere.RelevantAsset}
            />
          </div>
        );

      case RelevantAssetView.PDFInfo:
        return <ViewEditContentManagement isRelevantAsset={true} />;

      default:
        return null;
    }
  };

  const getSelectedAssetForListView = () => {
    let selectedIds: number[] = [];
    if (!isEmpty(selectedAssets)) {
      selectedIds = selectedAssets.map((item) => item?.asset_id);
    }
    return selectedIds;
  };

  const getIsIdUse = (selectedRowKeys: any[], key: number) => {
    const citationIds = citationList?.map((item) => item?.asset_id);
    const selectedRowKeysIds = [...selectedRowKeys];

    const citationIdsCount = citationIds?.filter((id) => id === key)?.length;
    const selectedRowKeysIdsCount = selectedRowKeysIds?.filter((id) => id === key)?.length;
    const prevSelectedAssetsIdsCount = [...selectedAssets]?.filter(
      (item) => item.asset_id === key
    )?.length;

    if (prevSelectedAssetsIdsCount) {
      return citationIdsCount === selectedRowKeysIdsCount;
    } else {
      return selectedRowKeysIdsCount === 1 || citationIdsCount === selectedRowKeysIdsCount;
    }
  };

  const rowSelection = {
    selectedRowKeys: getSelectedAssetForListView(),

    onChange: (selectedRowKeys: any[]) => {
      let selectedIds: number[] = [];

      if (!isEmpty(selectedRowKeys)) {
        selectedRowKeys?.forEach((key) => {
          const isIdUse = getIsIdUse(selectedRowKeys, key);

          if (isIdUse) {
            selectedIds.push(key);
          }
        });
      } else {
        selectedIds = [];
      }

      let updatedContent: ICitations[] = [];
      if (!isEmpty(citationList)) {
        citationList.forEach((item) => {
          if (selectedIds.includes(item?.asset_id)) {
            updatedContent.push({ ...item });
          }
        });
      }
      setSelectedAssets(updatedContent);
    },
  };

  const tableColumns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "33%",
      render: (_: string, content: ICitations) => (
        <>
          <span className="td-label">Title : </span>
          <strong
            className={`title-col  d-flex d-flex-middle`}
            onClick={() => toggleCardCollapseForCitation(content)}>
            <div
              className="file-bg cursor-pointer"
              style={{ backgroundImage: `url(${content?.pre_signed_url})` }}></div>
            <span
              className="cursor-pointer"
              onClick={() => handleViewEditContentNavigate(content.asset_id, false)}>
              {content.title !== "" && content.title ? content.title : content.original_file_name}
            </span>
          </strong>
        </>
      ),
    },
    {
      title: "Type",
      key: "docType",
      width: "33%",
      render: (content: ICitations) => (
        <>
          <span className="td-label">Type : </span>
          <span className="doc-type">
            <div className="profile-pic">
              <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />
            </div>
            {content?.file_type}
          </span>
        </>
      ),
    },
    {
      title: "Page No.",
      key: "pageNo",
      width: "33%",
      render: (content: ICitations) => (
        <>
          <span className="td-label">Page No.:</span>
          <span className="doc-type">{content?.page >= 0 ? content?.page + 1 : 0}</span>
        </>
      ),
    },
  ];

  const handleClose = () => {
    dispatch(setRelevantAssetViewType(ViewType.GRID));
    dispatch(setSelectedMessageCitations([]));
    dispatch(setSelectedRelevantAssetMsgId(null));

    if (routeHistory[1].includes(PATHS.viewEditContentManagement)) {
      navigate(PATHS.home);
    } else {
      navigate(routeHistory[1] || PATHS.home);
    }
  };

  const handleListIcon = () => {
    document.body.classList.remove("relevant-assets-page"); // Toggle the class on body tag
    dispatch(setRelevantAssetView(RelevantAssetView.None));
    dispatch(setRelevantAssetViewType(ViewType.LIST));
  };

  return (
    <>
      <div
        className="relevant-assets-wrap"
        hidden={
          relevantAssetView === RelevantAssetView.PDF && relevantAssetViewType === ViewType.LIST
        }>
        <Card className="relevant-assets">
          <div className="top-bar d-flex d-flex-middle d-flex-between">
            <h2>Relevant Assets</h2>

            <div className="d-flex">
              <span className="view-change">
                <Button
                  type="text"
                  className={`view-type ${relevantAssetViewType === ViewType.GRID ? "active" : ""}`}
                  onClick={() => dispatch(setRelevantAssetViewType(ViewType.GRID))}>
                  <i className="ri-layout-grid-fill"></i>
                </Button>
                <Button
                  type="text"
                  className={`view-type ${relevantAssetViewType === ViewType.LIST ? "active" : ""}`}
                  onClick={handleListIcon}>
                  <i className="ri-list-unordered"></i>
                </Button>
              </span>

              <Button
                type="text"
                className="page-close-btn close-icon"
                onClick={handleClose}
                icon={<i className="ri-close-fill" />}
              />
            </div>
          </div>

          <div className="bottom-bar d-flex d-flex-middle d-flex-between">
            <div className="ant-tabs-nav-container">
              <Checkbox
                className="select-label"
                checked={selectedAssets?.length === citationList?.length && !isEmpty(citationList)}
                onChange={(e) =>
                  e.target.checked ? setSelectedAssets([...citationList]) : setSelectedAssets([])
                }>
                Select All <span>(Showing {citationList.length} results)</span>
              </Checkbox>
            </div>

            <div className="ant-tabs-extra-content">
              {/* <BookmarkIcon /> */}
              {/* <Button
                onClick={handleChatWithAssetFilter}
                type="primary"
                disabled={selectedAssets.length ? false : true}
                className="btn chat-document"
                icon={<i className="ci-small-chat-icon ci"></i>}>
                Chat
              </Button> */}
              {/* <Button
                type="text"
                className="text-btn"
                icon={<i className="ri-delete-bin-line"></i>}>
                Delete
              </Button> */}
              <ContentChatButton
                {...{
                  selectedContents: getSelectedAssets() || [],
                  fromWhere: FromWhere.RelevantAsset,
                }}
              />
            </div>
          </div>

          <div className="page-scroll">
            {relevantAssetViewType === ViewType.GRID ? (
              <Row className="tab-row-inner">
                {citationList.length
                  ? citationList.map((item, index) => (
                      <Fragment key={`citation-${item.id}-${index}`}>
                        <AssetFileCard
                          data={item}
                          handleSetCheckedAssets={handleSetCheckedAssets}
                          isChecked={
                            selectedAssets.find((e) => e.s_no === item.s_no) ? true : false
                          }
                          type={FileCardType.UPLOADED}
                          key={uniqueId()}
                          onCardClick={() => toggleCardCollapseForCitation(item)}
                          onClick={() => handleViewEditContentNavigate(item.asset_id, false)}
                        />
                      </Fragment>
                    ))
                  : ""}
              </Row>
            ) : (
              <Row className="tab-row-inner list-view">
                <Col span={24}>
                  <Card className="global-table-card">
                    <Table
                      className="global-table responsive-table"
                      rowKey="asset_id"
                      columns={tableColumns}
                      dataSource={citationList?.length ? citationList : []}
                      tableLayout="fixed"
                      rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                      }}
                      scroll={{ y: "calc(100vh - 330px)", x: "100" }}
                      pagination={false}
                    />
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Card>
        {relevantAssetViewType === ViewType.GRID && renderAssetView()}
      </div>
      {relevantAssetViewType === ViewType.LIST && renderAssetView()}

      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                navigate(PATHS.relevantAssets, {
                  state: { s_no: 0, fromWhere: FromWhere.ConversationalSearch },
                });
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RelevantAssets;
