import { Key } from "react";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";
import { store } from "../store";
import { deleteRequest, get, post, put } from "./apiClients";
import { PATHS, TaxonomyTitle, TaxonomyItem, PageLimit } from "../shared";
import { TaxonomyCreate } from "../store/taxonomy/taxonomy.interface";
import {
  addTaxonomy,
  setAllTaxonomyData,
  setTaxonomyDeleteLoading,
  setTaxonomyTableLoading,
  setTaxonomyTree,
  setTreeTableLoading,
  setTaxonomyByIndustry,
  setTaxonomyByFunction,
  setTaxonomyByAssetType,
  setIsTaxonomyByTitleLoading,
  setDeleteTreeTableLoading,
  deleteTaxonomy,
  setTotalTaxonomyCount,
  setTaxonomyCurrPage,
  setTaxonomyByPortfolios,
} from "../store/taxonomy/taxonomySlice";

export const fetchAllTaxonomy = async (page = 1) => {
  store.dispatch(setTaxonomyTableLoading(true));
  try {
    const res = await get(`/core/taxonomy?page=${page - 1}&limit=${PageLimit.Taxonomy}`);
    const taxonomyRes = res?.content ?? [];
    const totalElementsRes = res?.totalElements ?? 0;
    const currPageRes = res?.number ? res?.number + 1 : 1;

    store.dispatch(setAllTaxonomyData(taxonomyRes));
    store.dispatch(setTotalTaxonomyCount(totalElementsRes));
    store.dispatch(setTaxonomyCurrPage(currPageRes));
  } catch (error) {
    console.log(error);
    store.dispatch(setAllTaxonomyData([]));
    store.dispatch(setTotalTaxonomyCount(0));
    store.dispatch(setTaxonomyCurrPage(1));
    return error;
  } finally {
    store.dispatch(setTaxonomyTableLoading(false));
  }
};

export const createTaxonomy = async (request: TaxonomyCreate, navigate: NavigateFunction) => {
  try {
    const res = await post(`/core/taxonomy`, request);
    store.dispatch(addTaxonomy(res.data));
    if (res?.status === 200 && res?.data?.taxonomyId) {
      toast.success("Taxonomy created successfully");
      navigate(`${PATHS.adminTaxonomyOverview}/${res?.data?.taxonomyId}`, {
        state: { isEditMode: true },
      });
    }
    return res;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.error ?? "Failed to create taxonomy");
  } finally {
  }
};

export const fetchTaxonomyById = async (taxonomyId: number | undefined) => {
  try {
    store.dispatch(setTreeTableLoading(true));
    const res = await get(`/core/taxonomy/${taxonomyId}`);
    store.dispatch(setTaxonomyTree(res));
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};

export const updateTaxonomy = async (
  taxonomyId: number | undefined,
  request: {
    title: string;
    purpose: string;
    taxonomyType: string;
    levels: number;
    synonyms: string;
  }
) => {
  try {
    store.dispatch(setTreeTableLoading(true));
    await put(`/core/taxonomy/${taxonomyId}`, request);
    // fetchTaxonomyById(taxonomyId);
    toast.success("Successfully updated");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.message ?? "Failed to update node");
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};

export const deleteTaxonomyManagement = async (taxonomyId: number) => {
  store.dispatch(setTaxonomyDeleteLoading(true));
  try {
    await deleteRequest(`/core/taxonomy/${taxonomyId}`);
    store.dispatch(deleteTaxonomy(taxonomyId));

    toast.success("Taxonomy deleted successfully.");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.message ?? "Failed to delete taxonomy");
  } finally {
    store.dispatch(setTaxonomyDeleteLoading(false));
  }
};

export const createNewTaxonomyNode = async (
  request: {
    taxonomyNodeName: string;
    description: string;
    parentTaxonomyNodeId: number | null | undefined;
    taxonomyId: number;
    synonyms: string;
    // taxonomyNodeLevel: number;
  },
  taxonomyNodeId: number,
  taxonomy: TaxonomyItem[],
  setTaxonomy: (v: TaxonomyItem[]) => void,
  resetInput: () => void
) => {
  try {
    store.dispatch(setTreeTableLoading(true));

    const { data } = await post(`/core/taxonomyNodes`, request);

    const updateNode = (nodes: TaxonomyItem[]): TaxonomyItem[] => {
      return nodes.map((node) => {
        if (node.taxonomyNodeId === taxonomyNodeId) {
          return {
            ...node,
            taxonomyNodeId: data?.taxonomyNodeId,
            taxonomyNodeName: request?.taxonomyNodeName,
          };
        }
        if (node.children.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }
        return node;
      });
    };

    setTaxonomy(updateNode(taxonomy));
    fetchTaxonomyById(request?.taxonomyId);
    resetInput();
    toast.success("Created node successfully");
  } catch (error: any) {
    console.log(error);
    toast.error(error?.message ?? "Failed to create node");
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};

export const createNewFlatTaxonomyNode = async (
  request: {
    taxonomyNodeName: string;
    description: string;
    parentTaxonomyNodeId: number | null | undefined;
    taxonomyId: number;
    taxonomyNodeLevel: number;
  },
  taxonomyId: number
) => {
  try {
    store.dispatch(setTreeTableLoading(true));

    const { data } = await post(`/core/taxonomyNodes`, request);
    toast.success("Node created  successfully");
    fetchTaxonomyById(taxonomyId);

    return data;
  } catch (error: any) {
    console.log(error);
    toast.error(error?.message ?? "Failed to create node");
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};

export const updateTaxonomyNode = async (
  taxonomyNodeId: number | undefined,
  isTableLoading: boolean,
  request: {
    description: string | undefined;
    taxonomyNodeName: string;
    synonyms: string | undefined;
  },
  taxonomy: TaxonomyItem[],
  setTaxonomy?: (v: TaxonomyItem[]) => void,
  resetInput?: () => void
) => {
  try {
    isTableLoading
      ? store.dispatch(setTreeTableLoading(true))
      : store.dispatch(setDeleteTreeTableLoading(true));

    const response = await fetchTaxonomyNodeById(taxonomyNodeId);
    const { taxonomyId, taxonomyNodeLevel, parentTaxonomyNodeId } = response;
    const bodyRequest = {
      parentTaxonomyNodeId,
      taxonomyId,
      taxonomyNodeLevel,
      ...request,
    };
    const res = await put(`/core/taxonomyNodes/${taxonomyNodeId}`, bodyRequest);

    const updateNode = (nodes: TaxonomyItem[]): TaxonomyItem[] => {
      return nodes.map((node) => {
        if (node.taxonomyNodeId === taxonomyNodeId) {
          return {
            ...node,
            description: request.description ?? "",
            synonyms: request.synonyms ?? "",
            taxonomyNodeName: request.taxonomyNodeName,
          };
        }
        if (node.children.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }
        return node;
      });
    };

    setTaxonomy && setTaxonomy(updateNode(taxonomy));
    resetInput && resetInput();
    toast.success("Node successfully updated");
    return res;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to update node");
    throw error;
  } finally {
    store.dispatch(setTreeTableLoading(false));
    store.dispatch(setDeleteTreeTableLoading(false));
  }
};

export const fetchTaxonomyNodeById = async (taxonomyNodeId: number | undefined) => {
  try {
    store.dispatch(setTreeTableLoading(true));
    const res = await get(`/core/taxonomyNodes/${taxonomyNodeId}`);
    return res;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};
export const deleteTaxonomyNodes = async (taxonomyNodeId: Key[], taxonomyId: number) => {
  try {
    store.dispatch(setDeleteTreeTableLoading(true));
    const res = await deleteRequest(`/core/taxonomyNodes/${taxonomyNodeId}`);
    fetchTaxonomyById(taxonomyId);
    toast.success("Nodes deleted successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to delete nodes");
  } finally {
    store.dispatch(setDeleteTreeTableLoading(false));
  }
};

export const deleteTaxonomyLastLevel = async (taxonomyId: number) => {
  try {
    store.dispatch(setDeleteTreeTableLoading(true));
    const res = await deleteRequest(`core/taxonomy/${taxonomyId}/last-level`);

    toast.success("Taxonomy level deleted successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to delete nodes");
  } finally {
    store.dispatch(setDeleteTreeTableLoading(false));
  }
};

export const moveAllChildToParent = async (taxonomyNodeId: number, taxonomyId: number) => {
  try {
    store.dispatch(setDeleteTreeTableLoading(true));
    await put(`/core/taxonomyNodes/moveChildren/${taxonomyNodeId}`);
    await fetchTaxonomyById(taxonomyId);

    toast.success("Child nodes updated successfully");
  } catch (error: any) {
    toast.error(error?.message ?? "Failed to update child nodes");
  } finally {
    store.dispatch(setDeleteTreeTableLoading(false));
  }
};

export const getTaxonomyByTitle = async (title: string) => {
  try {
    store.dispatch(setIsTaxonomyByTitleLoading(true));
    const res = await get(`/core/taxonomy/title/${title}`);
    if (title === TaxonomyTitle.Industry) {
      store.dispatch(setTaxonomyByIndustry(res));
    } else if (title === TaxonomyTitle.Function) {
      store.dispatch(setTaxonomyByFunction(res));
    } else if (title === TaxonomyTitle.AssetType) {
      store.dispatch(setTaxonomyByAssetType(res));
    } else if (title === TaxonomyTitle.Portfolios) {
      store.dispatch(setTaxonomyByPortfolios(res));
    }
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch(setIsTaxonomyByTitleLoading(false));
  }
};

export const getTaxonomyNodesBySearch = async (taxonomyId: number, searchText: string) => {
  try {
    store.dispatch(setTreeTableLoading(true));
    const res = await get(`/core/taxonomy/${taxonomyId}?nameOrDescription=${searchText}`);
    store.dispatch(setTaxonomyTree(res));
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    store.dispatch(setTreeTableLoading(false));
  }
};
