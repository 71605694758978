import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Markdown from "react-markdown";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { Button, Divider, Image, Skeleton, Tooltip } from "antd";
import { ExclamationCircleOutlined, InfoCircleFilled } from "@ant-design/icons";
import "./ChatPanel.scss";
import { RootState } from "../../../store";
import configs from "../../../shared/config";
import WhisperAudioFile from "./WhisperAudioFile";
import WhisperTextToSpeech from "./WhisperTextToSpeech";
import RelevanAssets from "../../assets/RelevantAssets";
import {
  resetProfileResumes,
  setIsAllProfileLoading,
  setProfileResume,
} from "../../../store/profile/profileSlice";
import { updateViewCount } from "../../../services/contentManagement";
import { UploadAssets } from "../../uploadAssetsContentMangement";
import { ConversationFileCard } from "../publishingQueue/ConversationFileCard";
import { TFiltersType } from "../../../store/filtersFromTaxonomyData/filters.interface";
import { messageComment, saveStreamChatReply } from "../../../services/conversationalSearch";
import { IContent, IContentFilter } from "../../../store/contentManagement/content.interface";
import {
  ChatActions,
  ChatLanguage,
  ChatMessageType,
  ChatUseCase,
  defaultLogo,
  fileType,
  FromWhere,
  getAllIds,
  getFilesInContext,
  getLocalStorageItem,
  getUserId,
  isEmpty,
  Model,
  PATHS,
  renderFileTypeIcon,
  uniqueId,
  useChats,
  userType,
} from "../../../shared";
import {
  contentByIdInitialValue,
  setContentById,
  setContentFilesInContext,
  setContentSelectedIds,
  setSelectedAllTagsByAssestId,
  setUpdateAllContentFilter,
  setUpdateAllSourceFilter,
  tagsByAssestIdInitValue,
} from "../../../store/contentManagement/contentSlice";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import {
  IFileINContext,
  IMessages,
  ITags,
} from "../../../store/conversationalSearch/conversationalSearch.interface";
import {
  addNewChatHistory,
  addNewQuestionAnswer,
  setChatAction,
  setContentFilterForNewChat,
  setEmptyUploadFileList,
  setIsNewContextFilterApplied,
  setIsStreamingStart,
  setLoadingMessageId,
  setNewChatHistoryId,
  setNewChatHistoryTitle,
  setNewChatMessageInChatHistory,
  setSelectedFilesForAssetFilter,
  setSelectedRelevantAssetMsgId,
  setSelectedReleventAsset,
  setStartNewChatFromInsight,
  setStartNewConversation,
  setUpdateLastInfoMessage,
  setUpdateUploadFileList,
  setUserQuestion,
  updateAIAnswer,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { getSubdomain } from "../../../services/apiClients";

let chatHistoryId: number = -1;
let isNewChat: boolean = false;

const ChatPanel = () => {
  const [scaleStep] = useState(0.5);
  const [, setValue] = useState("global");
  const [, setIsComment] = useState(false);
  const [question, setQuestion] = useState("");
  const [visible, setVisible] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [selectedImageURL] = useState<string | undefined>("");
  const [isSaveChatReply, setIsSaveChatReply] = useState(false);
  const [isRetryBtnClick, setIsRetryBtnClick] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isNewChatStarted, setIsNewChatStarted] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState(false);
  const [isMessageSentAfterChange, setIsMessageSentAfterChange] = useState(false);
  const [chatUseCase, setChatUseCase] = useState<ChatUseCase>(ChatUseCase.default);
  const [disableInputWhileRecording, setDisableInputWhileRecording] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    chatLanguage,
    userQuestion,
    isNetworkOpen,
    uploadFileList,
    isStreamingStart,
    loadingMessageId,
    chatContentFilter,
    selectedChatHistory,
    selectedReleventAsset,
    startNewChatFromInsight,
    isNewContextFilterApplied,
    selectedRelevantAssetMsgId,
    selectedFilesForAssetFilter,
  } = useSelector((state: RootState) => state.conversationalSearch);
  const { profileResume } = useSelector((state: RootState) => state.profile);
  const { selectedContentIds } = useSelector((state: RootState) => state.content);
  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { appearance, findProfile } = useSelector((state: RootState) => state.app);

  const { id, messages } = selectedChatHistory;

  const { getSelectedFilterObject, clearAllChatStates, defaultConfigureState } = useChats();

  const isProcessInProgress = useRef(false);
  const wsRef = useRef<WebSocket | null>(null);
  const chatContainerRef: any = useRef(null);
  const assistantLogo = appearance?.assistantLogo?.url || defaultLogo.assistantLogo;

  useEffect(() => {
    if (location?.pathname === PATHS.profile) setChatUseCase(ChatUseCase.resume_finder);
    else setChatUseCase(ChatUseCase.default);
  }, [location?.pathname]);

  useEffect(() => {
    if (userQuestion) {
      setQuestion(userQuestion);
    }
  }, [userQuestion]);

  useEffect(() => {
    if (userQuestion && question) {
      dispatch(setUserQuestion(""));
      startNewChat();
    }
  }, [userQuestion, question]);

  useEffect(() => {
    if (isNewContextFilterApplied && !startNewChatFromInsight) {
      const infoMessage: IMessages | null = getInfoMessage();
      if (!isEmpty(messages) && messages[messages.length - 1]?.type === userType.INFO)
        dispatch(setUpdateLastInfoMessage({ message: infoMessage }));
      else if (infoMessage) dispatch(addNewQuestionAnswer({ chatId: id, queAns: [infoMessage] }));
    } else if (startNewChatFromInsight && isEmpty(selectedFilesForAssetFilter)) {
      const infoMessage: IMessages | null = getInfoMessage();
      if (infoMessage) {
        const aiMessage: IMessages = {
          ...infoMessage,
          type: userType.AI,
          userComments: "HIDEUSERCOMMENT",
          content: "Hello, how can I assist you today?",
        };
        dispatch(addNewQuestionAnswer({ chatId: id, queAns: [infoMessage, aiMessage] }));
      }
    }
  }, [isNewContextFilterApplied, selectedFilesForAssetFilter]);

  useEffect(() => {
    if (startNewChatFromInsight && isEmpty(messages)) {
      const infoMessage: IMessages | null = getInfoMessage();
      if (!isEmpty(messages) && messages[messages.length - 1]?.type === userType.INFO)
        dispatch(setUpdateLastInfoMessage({ message: infoMessage }));
      else if (infoMessage) {
        const aiMessage: IMessages = {
          ...infoMessage,
          type: userType.AI,
          userComments: "HIDEUSERCOMMENT",
          content: "Hello, how can I assist you today?",
        };
        dispatch(addNewQuestionAnswer({ chatId: id, queAns: [infoMessage, aiMessage] }));
      }
    }
  }, [startNewChatFromInsight, messages]);

  const getInfoMessage = (): IMessages | null => {
    if (!isNewContextFilterApplied && isMessageSentAfterChange && !startNewChatFromInsight)
      return null;
    let messageText: string = !isEmpty(selectedFilesForAssetFilter)
      ? "Conversation started for the selected context"
      : "";

    if (
      !messageText &&
      !isEmpty(chatContentFilter) &&
      chatContentFilter?.pageLocation &&
      chatContentFilter?.pageLocation !== PATHS.home
    ) {
      messageText = !isEmpty(chatContentFilter)
        ? "Conversation started for the selected context"
        : "";
    }
    if (!messageText) {
      setValue("global");
      messageText = "Conversation started for the global context";
    } else {
      setValue("context");
    }
    setIsMessageSentAfterChange(true);

    if (messageText)
      return {
        id: uniqueId(),
        type: userType.INFO,
        content: JSON.stringify({
          filter:
            !isEmpty(chatContentFilter) && chatContentFilter?.pageLocation
              ? chatContentFilter
              : null,
          pageLocation: !isEmpty(chatContentFilter)
            ? chatContentFilter?.pageLocation
            : location.pathname,
          filesInContext: !isEmpty(selectedFilesForAssetFilter)
            ? selectedFilesForAssetFilter
            : null,
          text: messageText,
        }),
        contentType: "text",
        citations: [],
        error: false,
        answerId: "",
        thumbsUp: false,
        thumbsDown: false,
        userComments: "",
        optimised_Question: "",
        isStopGenerating: "FALSE",
        isShowGeneratingLabel: false,
        language: chatLanguage,
        profileDetails: findProfile ? profileResume : null,
      };
    else return null;
  };

  const startNewChat = () => {
    setIsNewChatStarted(true);
    isNewChat = true;
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      contentType: "text",
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
      language: chatLanguage,
      profileDetails: findProfile ? profileResume : null,
    };

    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };

    let messages: IMessages[] = [];
    const infoMessage: IMessages | null = getInfoMessage();

    if (infoMessage) {
      messages = startNewChatFromInsight
        ? [...selectedChatHistory.messages, userMessage, aiReply]
        : [infoMessage, userMessage, aiReply];

      dispatch(setIsNewContextFilterApplied(false));
    } else messages = [userMessage, aiReply];
    const newChatHistory = {
      id: uniqueId(),
      title: "Greetings",
      messages: messages,
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      createdOn: dayjs().toISOString(),
      chatTaxonomies: [getSelectedFilterObject()],
      fileContext: getFilesInContext(
        selectedFilesForAssetFilter,
        selectedReleventAsset,
        selectedChatHistory,
        "saveAIReply"
      ),
      resumeFinder: false,
    };
    chatHistoryId = newChatHistory.id;
    dispatch(addNewChatHistory(newChatHistory));
    dispatch(setLoadingMessageId(aiReply.id || 0));
    connectWebSocket(() => {
      askToWebSocket();
    });
  };

  const getLastContext = () => {
    if (chatContentFilter?.pageLocation) return chatContentFilter;
    const infoMessage = messages.filter((e: any) => e.type === "info").reverse()[0];

    return infoMessage ? JSON.parse(infoMessage.content)?.filter : chatContentFilter;
  };

  const getTaxonomyFilter = () => {
    let contextFilterObj: any = getLastContext();

    const taxonomyFilterObject = getSelectedFilterObject();
    if (contextFilterObj) {
      taxonomyFilterObject.tags.map((e: ITags) => {
        if (e.taxonomyName === TFiltersType.Functions) {
          e.taxonomyNodeIds = contextFilterObj.function ? contextFilterObj.function : [];
          delete e.taxonomyName;
        }
        if (e.taxonomyName === TFiltersType.Industry) {
          e.taxonomyNodeIds = contextFilterObj.industry ? contextFilterObj.industry : [];
          delete e.taxonomyName;
        }
        if (e.taxonomyName === TFiltersType.AssetType) {
          e.taxonomyNodeIds = contextFilterObj.assetType ? contextFilterObj.assetType : [];
          delete e.taxonomyName;
        }
      });
      if (contextFilterObj.documentType)
        taxonomyFilterObject.fileType = contextFilterObj.documentType;
      if (contextFilterObj.startDate) {
        taxonomyFilterObject.startDate = contextFilterObj.startDate;
        taxonomyFilterObject.endDate = contextFilterObj.endDate;
      }
      if (contextFilterObj.isBookmarked)
        taxonomyFilterObject.is_bookmarked = contextFilterObj.isBookmarked;
      if (!isEmpty(contextFilterObj.contentType) && contextFilterObj?.contentType?.length === 1)
        taxonomyFilterObject.is_owner = contextFilterObj.contentType.includes("personal");
    }
    return taxonomyFilterObject;
  };

  const askToWebSocket = () => {
    let files_in_context: IFileINContext[] = [];
    files_in_context = !isEmpty(selectedContentIds)
      ? getFilesInContext(
          selectedFilesForAssetFilter,
          selectedReleventAsset,
          selectedChatHistory,
          "ws"
        )
      : [];

    const taxonomyFilter = (data: any): any => {
      const updatedTags = data?.tags
        ?.filter((tag: any) => tag.taxonomyId !== null) // Remove objects with null taxonomyId
        .map(({ taxonomyId, taxonomyNodeIds }: any) => ({
          taxonomyId,
          taxonomyNodeIds,
        }));

      return {
        ...data,
        tags: updatedTags,
      };
    };

    if (wsRef.current) {
      const request = {
        question: isRetryBtnClick ? messages[messages.length - 2].content : question.trim(),
        chat_context: selectedChatHistory.messages.slice(
          0,
          isRetryBtnClick ? messages?.length - 2 : messages?.length
        ),
        files_in_context: files_in_context,
        taxonomy_filter: isEmpty(files_in_context) ? taxonomyFilter(getTaxonomyFilter()) : null,
        use_case: chatUseCase,
        language: chatLanguage,
        namespace: getSubdomain(),
      };

      setQuestion("");
      setIsRetryBtnClick(false);
      wsRef.current.send(JSON.stringify(request));
    } else {
      console.error("WebSocket connection is not available.");
    }
  };

  const connectWebSocket = (callback?: () => void) => {
    findProfile && dispatch(setIsAllProfileLoading(true));
    const token = getLocalStorageItem("token_foundationKM");
    let newWs = new WebSocket(`${configs.WEB_SOCKET_URL}rag/rag-with-metadata?token=${token}`);

    newWs.onopen = () => {
      wsRef.current = newWs;
      if (typeof callback === "function") {
        callback();
      }
    };

    let currentType = "";
    let currentContent = "";
    newWs.onmessage = (event: any) => {
      if (event?.data) {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case ChatMessageType.start:
            if (data.content_type === null) {
              currentType = "stream";
              currentContent = "";
              dispatch(setIsStreamingStart(true));
            } else if (
              data.content_type === "optimised_question" ||
              data.content_type === "answer"
            ) {
              currentType = data.content_type;
            }
            break;

          case ChatMessageType.stream:
            if (data.content_type === null) {
              if (currentType === "optimised_question") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "optimised_Question",
                    ans: currentContent,
                  })
                );
              } else if (currentType === "answer") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "content",
                    ans: currentContent,
                  })
                );
              }
            }
            break;

          case ChatMessageType.end:
            if (data.content_type === "optimised_question") {
              currentContent = "";
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "isShowGeneratingLabel",
                  ans: true,
                })
              );
            } else if (data.content_type === "answer") {
              currentContent = "";
            } else {
              dispatch(setIsStreamingStart(false));
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "answerId",
                  ans: "m_" + Date.now(),
                })
              );
              dispatch(setLoadingMessageId(-1));
              setIsSaveChatReply(true);
            }
            break;

          case ChatMessageType.blob:
            if (data.content_type === "citations") {
              let citations = JSON.parse(data.message);
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "citations",
                  ans: citations,
                })
              );
            } else if (data.content_type === "answer") {
              let resumeData = JSON.parse(data.message);
              dispatch(setProfileResume(resumeData?.resumes));
            }
            break;

          case ChatMessageType.error:
            setIsNewChatStarted(false);
            dispatch(setIsStreamingStart(false));
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "error",
                ans: true,
              })
            );
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "content",
                ans: data.message,
              })
            );
            break;

          default:
            // Handle unknown message types
            break;
        }
      }
    };

    newWs.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    newWs.onclose = (event) => {
      // newWs?.close();
      wsRef.current = null;
      if (event?.reason === "NETWORK_CLOSE") {
        setErrorMessage(true);
        dispatch(setIsStreamingStart(false));
      }
    };
  };

  useEffect(() => {
    if (isSaveChatReply) {
      SaveAIStreamReply();
      setIsSaveChatReply(false);
    }
  }, [isSaveChatReply]);

  useEffect(() => {
    if (uploadFileList?.length && uploadFileList?.length === selectedFiles?.length) {
      SaveAIStreamReply();
    } else if (
      uploadFileList?.length &&
      selectedChatHistory?.id === -1 &&
      !isProcessInProgress?.current
    ) {
      isProcessInProgress.current = true;
      isNewChat = true;
      SaveAIStreamReply();
    }
  }, [selectedFiles, uploadFileList]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  const isAtBottom = (container: HTMLDivElement): boolean => {
    const threshold = 1; // Adjust this threshold if necessary
    const scrollTop = Math.round(container.scrollTop);
    const scrollHeight = Math.round(container.scrollHeight);
    const clientHeight = Math.round(container.clientHeight);
    const atBottom = scrollHeight - scrollTop <= clientHeight + threshold;
    return atBottom;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      setShowScrollButton(false);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  };

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      setShowScrollButton(!isAtBottom(container));
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  }, [messages]);

  const SaveAIStreamReply = async () => {
    let sliceCount =
      !isEmpty(messages) &&
      messages?.length > 2 &&
      messages[messages.length - 3].type === userType.INFO
        ? -3
        : -2;
    if (startNewChatFromInsight) sliceCount = -4;
    let messageIndex = sliceCount === -3 ? 1 : 0;
    const request = {
      id: isNewChat ? null : selectedChatHistory?.id,
      configSettingId: 1,
      title: isNewChat ? "Greetings" : selectedChatHistory.title,
      messages: messages.length ? messages.slice(sliceCount) : [],
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      message: isNewChat ? messages[messageIndex]?.content : "",
      createdOn: selectedChatHistory.createdOn || new Date().toISOString(),
      fileContext: getFilesInContext(
        selectedFilesForAssetFilter,
        selectedReleventAsset,
        selectedChatHistory,
        "saveAIReply"
      ),
      language: chatLanguage,
      resumeFinder: findProfile,
    };
    if (isNewChat) {
      Object.assign(request, {
        chatTaxonomies: [getSelectedFilterObject()],
      });
    } else {
      Object.assign(request, { chatTaxonomies: selectedChatHistory.chatTaxonomies });
    }
    let addNewHistory = false;
    if (selectedFiles?.length || uploadFileList?.length) {
      request.title = "File Upload";
      const newMessage = {
        id: null,
        type: "human",
        contentType: "file",
        content: JSON.stringify(uploadFileList),
        citations: [],
        error: false,
        answerId: "",
        thumbsUp: false,
        thumbsDown: false,
        userComments: "",
        optimised_Question: "",
        isStopGenerating: "FALSE",
        isShowGeneratingLabel: false,
        language: chatLanguage,
        profileDetails: findProfile ? profileResume : null,
      };

      request.messages = [...request.messages, newMessage];
      if (!isNewChat) dispatch(setNewChatMessageInChatHistory(newMessage));
      setSelectedFiles([]);
      addNewHistory = true;
    }
    try {
      const response = await saveStreamChatReply(request);
      if (response && response.status === 200 && isNewChat) {
        const { id, title } = response.data;
        dispatch(setNewChatHistoryId({ oldId: chatHistoryId, newId: id }));
        dispatch(setNewChatHistoryTitle({ chatHistoryId: id, title: title }));
        setIsNewChatStarted(false);
        if (addNewHistory) dispatch(addNewChatHistory(response.data));
      }
      dispatch(setStartNewChatFromInsight(false));
    } catch (error) {
      setIsNewChatStarted(false);
      console.log("Error while saving AI stream reply", error);
    }
    dispatch(setIsStreamingStart(false));
    isNewChat = false;
  };

  const handleRetryBtn = () => {
    if (isNetworkOpen) {
      setIsRetryBtnClick(true);
      setErrorMessage();
      connectWebSocket(() => {
        askToWebSocket();
      });
    }
  };
  const handleNewTopicAction = () => {
    dispatch(setStartNewChatFromInsight(false));
    clearAllChatStates();
    defaultConfigureState();
    dispatch(removeFilterData([]));
    dispatch(setAllFileTypesSelected(fileType));
    dispatch(setSelectedReleventAsset([]));
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setStartNewConversation(false));
    dispatch(setUpdateUploadFileList([]));
    dispatch(setContentFilesInContext([]));
    dispatch(setContentSelectedIds([]));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(resetProfileResumes());
    dispatch(setContentFilterForNewChat({ pageLocation: "" }));
  };

  const handleClearContext = () => {
    dispatch(setContentFilesInContext([]));
    dispatch(removeFilterData([]));
    dispatch(setSelectedReleventAsset([]));
    dispatch(setUpdateUploadFileList([]));
    dispatch(setContentSelectedIds([]));
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(resetProfileResumes());
    dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
    dispatch(setIsNewContextFilterApplied(true));
    dispatch(setContentFilterForNewChat({ pageLocation: "" }));
  };

  const isDisableChat = () => {
    return (
      !isNetworkOpen ||
      isStreamingStart ||
      isAudioLoading ||
      (selectedChatHistory.id !== -1 && loadingMessageId === selectedChatHistory.id) ||
      isNewChatStarted ||
      disableInputWhileRecording
    );
  };

  const handleOldChat = () => {
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
      language: chatLanguage,
      profileDetails: findProfile ? profileResume : null,
    };

    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };

    let queAns: IMessages[] = [];
    queAns = [userMessage, aiReply];
    setQuestion("");
    dispatch(addNewQuestionAnswer({ chatId: id, queAns: queAns }));
    connectWebSocket(() => {
      askToWebSocket();
    });
  };

  const handleAskFurther = () => {
    dispatch(setStartNewChatFromInsight(false));
    if (id !== -1) {
      handleOldChat();
    } else {
      startNewChat();
    }
  };

  const setErrorMessage = (isError: boolean = false) => {
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "error",
        ans: isError,
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "content",
        ans: isError ? "Connection is closed. Please try again" : "",
      })
    );
  };

  const handleCopyClick = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      toast.success("Text copied successfully");
    } catch (error) {
      console.error("Error copying text:", error);
    }
  };

  // Message Like Dislike
  const handleMessageActions = async (message: IMessages, action: string) => {
    try {
      let thumbsUp = false,
        thumbsDown = false;
      if (action === ChatActions.Like) {
        thumbsUp = !message.thumbsUp;
        thumbsDown = false;
      } else {
        thumbsDown = !message.thumbsDown;
        thumbsUp = false;
      }
      dispatch(
        setChatAction({
          ...message,
          userComments: "",
          thumbsUp: thumbsUp,
          thumbsDown: thumbsDown,
        })
      );

      if (action === ChatActions.Dislike && thumbsDown) {
        setIsComment(true);
      }

      if (thumbsDown) toast.success("Feedback sent");

      const request = {
        chatId: selectedChatHistory.id,
        answerId: message.answerId,
        userComments: "",
        thumbsUp: thumbsUp,
        thumbsDown: thumbsDown,
      };

      const response = await messageComment(request);
      if (response && response.status === 200) {
      }
    } catch (error) {
      console.log("Error while message action", error);
    }
  };
  const handleStopGenerate = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "answerId",
        ans: "m_" + Date.now(),
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "isStopGenerating",
        ans: "TRUE",
      })
    );
    dispatch(setLoadingMessageId(-1));
    dispatch(setIsStreamingStart(false));
    setIsSaveChatReply(true);
  };

  const handleNavigatePage = (content: { filter: IContentFilter; pageLocation: string }) => {
    dispatch(setUpdateAllContentFilter(content.filter));
    dispatch(setUpdateAllSourceFilter(content.filter));
    navigate(content.pageLocation, { state: { isReloadData: true } });
  };

  const handleNavigation = (assetId: number) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    updateViewCount(assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode: false,
        isReadOnly: true,
        fromWhere: FromWhere.ConversationalSearch,
      },
    });
  };

  const getFilesInContextFromMessage = (message: IMessages) => {
    try {
      return JSON.parse(message.content)?.filesInContext;
    } catch (_: unknown) {
      return {};
    }
  };

  useEffect(() => {
    dispatch(setSelectedRelevantAssetMsgId(null));
  }, []);

  return (
    <>
      <div className="chat-body-content body-scroll" ref={chatContainerRef}>
        {messages?.length > 0 && (
          <ul>
            {messages.map((message: IMessages, index) => {
              return (
                <React.Fragment key={index}>
                  {message.type === userType.AI ? (
                    <li className="left-chat">
                      <div className="ai-text-wrap">
                        {message?.optimised_Question && (
                          <div className="ai-searching ai-text">
                            <i className="ri-check-line"></i> Searching for :{" "}
                            <b>{message?.optimised_Question}</b>
                          </div>
                        )}
                        {message?.isShowGeneratingLabel && (
                          <div className="ai-generating  ai-text">
                            <i className="ri-check-line"></i> Generating answers for you...
                          </div>
                        )}
                      </div>

                      <div className="conversation-list" hidden={findProfile}>
                        <div className="chat-avatar">
                          <div className="user-icon">
                            <img
                              src={assistantLogo}
                              alt="Assistant Logo"
                              onError={(e) => (e.currentTarget.src = defaultLogo.assistantLogo)}
                            />
                          </div>
                        </div>

                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content position-relative">
                            {!message?.content && message.isStopGenerating !== "TRUE" ? (
                              <div className="p-wrap-content">
                                <span className="skeleton-loader">
                                  <Skeleton title={false} active paragraph={{ rows: 1 }} />
                                </span>
                              </div>
                            ) : (
                              <div className="p-wrap-content">
                                {message?.error ? (
                                  <ExclamationCircleOutlined className="error-icon" />
                                ) : null}
                                {message.isStopGenerating === "TRUE" &&
                                message.content === "Generating..." ? (
                                  <i style={{ color: "#9e9e9e" }}>User has stopped generating.</i>
                                ) : message.isStopGenerating === "TRUE" &&
                                  message.content !== "Generating..." ? (
                                  <>
                                    <Markdown
                                      className={
                                        message?.language === ChatLanguage.Arabic
                                          ? "mark-down arabic-language-change"
                                          : "mark-down"
                                      }>
                                      {message.content}
                                    </Markdown>
                                    <span className="stopped-generating">
                                      ...
                                      <br />
                                      <i>User has stopped generating.</i>
                                    </span>
                                  </>
                                ) : (
                                  <Markdown
                                    className={
                                      message?.language === ChatLanguage.Arabic
                                        ? "mark-down arabic-language-change"
                                        : "mark-down"
                                    }>
                                    {message.content}
                                  </Markdown>
                                )}
                              </div>
                            )}
                            {!isEmpty(message?.citations) && (
                              <div
                                className={`documents-view ${
                                  message?.id === selectedRelevantAssetMsgId &&
                                  "selected-documents-view"
                                }`}>
                                <RelevanAssets
                                  citations={message?.citations}
                                  messageId={message?.id}
                                />
                              </div>
                            )}

                            {message?.error ? (
                              <div className="retry-btn acrion-btn-3 position-absolute">
                                <Tooltip title="Regenerate">
                                  <Button
                                    className="m-10"
                                    onClick={handleRetryBtn}
                                    icon={<i className="ri-loop-right-fill" />}
                                  />
                                </Tooltip>
                              </div>
                            ) : null}
                            {!message?.error &&
                              message?.contentType !== "file" &&
                              selectedChatHistory.id !== -1 &&
                              message?.content &&
                              message?.userComments !== "HIDEUSERCOMMENT" && (
                                <div className="acrion-btn-3 position-absolute">
                                  <WhisperTextToSpeech text={message?.content} />

                                  <Tooltip title="Bad">
                                    <Button
                                      className={message?.thumbsDown ? "btn active" : "btn"}
                                      type="text"
                                      onClick={() => {
                                        handleMessageActions(message, ChatActions.Dislike);
                                      }}
                                      icon={<i className="ri-thumb-down-fill" />}>
                                      {/* Bad */}
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Copy">
                                    <Button
                                      className="btn"
                                      type="text"
                                      onClick={() => {
                                        handleCopyClick(message.content);
                                      }}
                                      icon={<i className="ri-file-copy-fill" />}>
                                      {/* Copy */}
                                    </Button>
                                  </Tooltip>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : message?.contentType === "file" && message.content ? (
                    JSON.parse(message.content).map((file: IContent, index: number) => (
                      <li
                        key={`message-${index}`}
                        className="right-chat file-card-chat"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          navigate(`${PATHS.viewContentManagementByUser}/${file?.asset_id}`, {
                            state: {
                              fromWhere: FromWhere.ConversationalSearch,
                              selectedChatHistoryId: selectedChatHistory?.id,
                            },
                          });
                        }}>
                        <ConversationFileCard {...{ file }} />
                      </li>
                    ))
                  ) : message.type === userType.INFO ? (
                    <li className="info-message">
                      <Divider>
                        {message.content.includes("{")
                          ? JSON.parse(message.content)?.text
                          : message.content}
                        {getFilesInContextFromMessage(message)?.length ? (
                          <span className="tooltip-wrap">
                            <Tooltip
                              trigger={"click"}
                              placement="bottomRight"
                              overlay={
                                getFilesInContextFromMessage(message)?.length ? (
                                  <div>
                                    <ul>
                                      <li key={"01"} className="title-asset">
                                        Referenced assets
                                      </li>
                                      {(getFilesInContextFromMessage(message) || []).map(
                                        (item: IFileINContext, index: number) => (
                                          <li
                                            title={item.title}
                                            onClick={() => handleNavigation(item.asset_id)}
                                            key={index}
                                            className="referred-file-wrap">
                                            <span className="file-icon">
                                              <img
                                                src={renderFileTypeIcon(item.file_type)}
                                                alt="Icon"
                                              />
                                            </span>
                                            {item.title}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                ) : (
                                  false
                                )
                              }
                              overlayClassName="asset-overlay">
                              <InfoCircleFilled
                                className="info-circle"
                                onClick={() =>
                                  JSON.parse(message.content)?.filesInContext?.length
                                    ? ""
                                    : handleNavigatePage(JSON.parse(message.content))
                                }
                              />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}
                      </Divider>
                    </li>
                  ) : (
                    <li className="right-chat">
                      <div className="conversation-list">
                        <div className="chat-avatar">
                          <div className="user-icon">
                            <i className="ri-user-fill"></i>
                          </div>
                        </div>

                        <div className="ctext-wrap">
                          <div className="ctext-wrap-content position-relative">
                            <div className="p-wrap-content">{message.content}</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
      <div className="chat-footer">
        <div className="chat-footer-top position-relative d-flex d-flex-middle d-flex-between">
          <Button
            onClick={handleNewTopicAction}
            className="new-topic-btn"
            icon={<i className="ci ci-new-topic" />}>
            New
          </Button>
          {!!showScrollButton && (
            <div className="chat-scroll-down position-absolute" onClick={scrollToBottom}>
              <i className="ri-arrow-down-line" />
            </div>
          )}
          {!isEmpty(selectedFilesForAssetFilter) && (
            <Button
              onClick={handleClearContext}
              danger={true}
              className="clear-context-btn new-topic-btn ">
              Clear Context
            </Button>
          )}
        </div>

        <div className="chat-footer-bottom d-flex d-flex-middle">
          <div className="ask-me-input d-flex d-flex-middle position-relative">
            <div className="ant-input-affix-wrapper">
              <span className="ant-input-prefix">
                <Tooltip
                  placement="top"
                  title="Upload"
                  trigger="hover"
                  overlayClassName="tooltip-text">
                  <i
                    onClick={() => [dispatch(setEmptyUploadFileList()), setIsOpenUploadAsset(true)]}
                    className="ri-attachment-2"></i>
                </Tooltip>
              </span>
              <TextArea
                value={userQuestion ? "" : question}
                placeholder="Ask me anything or upload asset..."
                onPressEnter={(e) => {
                  e.preventDefault();
                  if (question.trim() && !isDisableChat()) {
                    handleAskFurther();
                  }
                }}
                disabled={isDisableChat()}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </div>

            <WhisperAudioFile
              setQuestion={setQuestion}
              isDisable={isStreamingStart}
              isAudioLoading={isAudioLoading}
              setIsAudioLoading={setIsAudioLoading}
              setDisableInputWhileRecording={setDisableInputWhileRecording}
            />

            <Button
              type="primary"
              disabled={!question.trim() || isDisableChat()}
              onClick={handleAskFurther}
              className="send-btn position-absolute">
              <i className="ri-send-plane-fill" />
            </Button>

            {/* Stop generating */}
            {isStreamingStart && (
              <Button
                type="primary"
                onClick={handleStopGenerate}
                className="send-btn position-absolute stop-generating">
                <i className="ri-stop-fill"></i>
              </Button>
            )}
          </div>
        </div>

        {isOpenUploadAsset && (
          <UploadAssets
            isModalOpen={isOpenUploadAsset}
            setIsModalOpen={setIsOpenUploadAsset}
            fromWhere={FromWhere.Source}
          />
        )}
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ChatPanel;
