import { IConfig } from ".";

const DEV_HOST = ["127.0.0.1", "localhost", "dev.foundationkm.greychain.ai"];
const PROD_HOST = ["ocktogen.ai", "demo.ocktogen.ai", "aecb.ocktogen.ai"];

const hostname = window?.location?.hostname;

let configs: IConfig = {
  BASE_URL: "https://dev-foundation-service-gpt.greychain.ai/service-gpt/",
  HOST: window.location.hostname,
  WEB_SOCKET_URL: "wss://dev-foundation-runner-gpt.greychain.ai/runner-gpt/",
};

if (
  DEV_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Development environment
  const SOURCE = "https://dev-foundation-service-gpt.greychain.ai/service-gpt/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
    WEB_SOCKET_URL: "wss://dev-foundation-runner-gpt.greychain.ai/runner-gpt/",
  };
} else if (
  PROD_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Production environment
  const SOURCE = "https://service-foundation.greychain.ai/service-gpt/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
    WEB_SOCKET_URL: "wss://runner-foundation.greychain.ai/runner-gpt/",
  };
}

export const socialLogin = {
  MicrosoftAppId: "3e8f708f-b732-4bf3-8f80-857d2d67b13a", //octagen test
  // MicrosoftAppId: "221ada24-de13-4df1-b11b-96e740b5cdf3",  //SharePoint Online
  // MicrosoftAppId: "77103a00-8feb-4a18-be86-2c8f088723b7", //suraj
  // MicrosoftAppId:  "b6f6ada6-bfd5-42c8-9fa6-977dc6b29ccd", //Grey Chain AI

  // GoogleAppId: "1008286053499-o9onu0fhgruj0necf4rmmeqneiu38vbl.apps.googleusercontent.com",
  GoogleAppId: "271628992929-isf2if1spc35qnkg86b8h87cirf2t9sk.apps.googleusercontent.com",
  redirectUri: "http://localhost:5173/",
  // redirectUri: "https://dev-foundationkm.greychain.ai",
  scopes: ["Sites.FullControl.All"],
  authority: "https://login.microsoftonline.com/achrafchad.onmicrosoft.com",
  domain: "greychainai.sharepoint.com",
  relativeUrl: "/sites/OctogenAi",
  OPENAI_API_KEY: "sk-local-dev-service-account-R0eh33K8r2BcJTIovGWyT3BlbkFJLKRZZ93WbaoiUjOCfYK0",
};

export default configs;
