import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  REHYDRATE,
  PERSIST,
  REGISTER,
  FLUSH,
  PAUSE,
  PURGE,
} from "redux-persist";
import localforage from "localforage";
import appSlice from "./appState/appSlice";
import homeSlice from "./home/homeSlice";
import authSlice from "./auth/auth.slice";
import usersSlice from "./userManagement/usersSlice";
import taxonomySlice from "./taxonomy/taxonomySlice";
import pdfViewerSlice from "./pdfViewer/pdfViewer.slice";
import contentSlice from "./contentManagement/contentSlice";
import filtersSlice from "./filtersFromTaxonomyData/filters.slice";
import conversationalSearchSlice from "./conversationalSearch/conversationalSearchSlice";
import accessGroupSlice from "./accessGroup/accessGroupSlice";
import dataConnectionSlice from "./dataConnection/dataConnectionSlice";
import profileSlice from "./profile/profileSlice";

localforage.config({
  driver: localforage.INDEXEDDB, // Use IndexedDB as the storage driver
  name: "knowledge-management", // Name of your app
  version: 1, // Version of the database
  storeName: "knowledge-management", // Name of the data store
});

// Define your persist configuration
const persistConfig = {
  key: "root",
  storage: localforage,
  whitelist: [
    "app",
    "auth",
    "user",
    "content",
    "taxonomy",
    "conversationalSearch",
    "accessGroup",
    "profile",
  ],
};

// Combine your reducers
const appReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  home: homeSlice,
  user: usersSlice,
  profile: profileSlice,
  content: contentSlice,
  taxonomy: taxonomySlice,
  pdfViewer: pdfViewerSlice,
  filtersData: filtersSlice,
  accessGroup: accessGroupSlice,
  dataConnection: dataConnectionSlice,
  conversationalSearch: conversationalSearchSlice,
});

// Define the type for your root reducer
type RootStateTemp = ReturnType<typeof appReducer>;

// Define the action type for the "initializationStore" action
type InitializationAction = { type: "initializationStore" };

// Create your rootReducer
const rootReducer = (
  state: RootStateTemp | undefined,
  action: AnyAction | InitializationAction
): RootStateTemp => {
  if (action.type === "initializationStore") {
    return appReducer(undefined, { type: "initializationStore" });
  }
  return appReducer(state, action);
};

// Persist your root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure your store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [REHYDRATE, PERSIST, REGISTER, FLUSH, PAUSE, PURGE],
      },
    }),
});

// Create a persistor for your store
export const persistor = persistStore(store);

// Export types for convenience
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
