import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IContent, IContentSliceProps, ITagsByAssestId, IContentFilter } from "./content.interface";
import {
  AssetUploadStatus,
  ContentGroup,
  FileLocation,
  PublishedOption,
  RelevantAssetView,
} from "../../shared";
import { autoRefreshedAssetById } from "../../services/contentManagement";
import { PURGE } from "redux-persist";
import { IFileINContext } from "../conversationalSearch/conversationalSearch.interface";

export const getFileTypeOptions = (list: any[]) => {
  const options: any[] = [];

  if (list && list?.length) {
    list.forEach((item) => {
      options.push({ value: item?.key, label: item?.key });
    });
  }

  return options;
};

export const tagsByAssestIdInitValue: ITagsByAssestId = {
  assetId: null,
  linkedTaxonomyNodes: {
    Industry: [],
    Function: [],
    "Asset Type": [],
  },
  taxonomyTree: [],
};

export const contentByIdInitialValue: IContent = {
  asset_id: -1,
  file_name: "",
  original_file_name: "",
  pre_signed_url: "",
  bucket_name: "",
  assetStatus: "",
  error_detail: null,
  user: {
    status: "",
    id: -1,
    username: "",
    name: "",
    email: "",
    userPermissions: [],
  },
  viewedOn: "",
  title: "",
  assetType: "",
  summary: "",
  tags: [],
  published: "",
  contributors: [],
  isShowFullSummary: false,
  isShowFullTags: false,
  isPublished: false,
  uploadedOn: "",
  function: "",
  industry: "",
  assetTypeSelected: "",
  modifiedOn: "",
  publishedOn: "",
  linkedTags: tagsByAssestIdInitValue,
  thumbnail_pre_signed_url: "",
  thumbnail_object_path: "",
  asset_url: null,
  file_type: "",
  inputFileName: "",
  visionParsed: false,
  summaryStatus: "",
  ai_generated_summary: null,
  total_pages: 0,
  is_bookmarked: false,
  is_liked: false,
  file_level: FileLocation.ALL,
  isSelected: false,
  src_type: "",
};

export const contentFilterField = {
  currentPage: "currentPage",
  publishedFilter: "publishedFilter",
  searchText: "searchText",
  isBookmarked: "isBookmarked",
  fileLevel: "fileLevel",
  contentType: "contentType",
  assetType: "assetType",
  documentType: "documentType",
  industry: "industry",
  function: "function",
  isApplied: "isApplied",
  isFilterVisible: "isFilterVisible",
  group: "group",
  isFilterChanged: "isFilterChanged",
};

export const contentFilterInitValue: IContentFilter = {
  currentPage: 1,
  publishedFilter: PublishedOption[0].value,
  searchText: "",
  isBookmarked: "",
  fileLevel: FileLocation.ALL,
  contentType: [],
  assetStatus: [],
  assetType: [],
  documentType: [],
  industry: [],
  function: [],
  isApplied: false,
  isFilterVisible: false,
  startDate: "",
  endDate: "",
  group: ContentGroup.MyContent,
  isFilterChanged: false,
};

export const sourceFilterInitValue: IContentFilter = {
  currentPage: 1,
  publishedFilter: PublishedOption[1].value,
  searchText: "",
  isBookmarked: "",
  fileLevel: FileLocation.ALL,
  contentType: [],
  assetType: [],
  documentType: [],
  industry: [],
  function: [],
  startDate: "",
  endDate: "",
  isApplied: false,
  isFilterVisible: false,
  group: ContentGroup.Sources,
  isFilterChanged: false,
};

export const adminFilterInitValue: IContentFilter = {
  currentPage: 1,
  publishedFilter: PublishedOption[0].value,
  searchText: "",
  isBookmarked: "",
  fileLevel: FileLocation.ALL,
  contentType: [],
  assetStatus: [],
  assetType: [],
  documentType: [],
  industry: [],
  function: [],
  startDate: "",
  endDate: "",
  isApplied: false,
  isFilterVisible: false,
  group: ContentGroup.Admin,
  isFilterChanged: false,
};

const initialState: IContentSliceProps = {
  contents: [],
  isContentTableLoading: false,
  isContentByIdLoading: false,
  contentById: contentByIdInitialValue,
  isEditContentLoading: false,
  selectedAllTagByAssestId: tagsByAssestIdInitValue,
  isTagsByAssestIdLoading: false,
  isUpdateTagsByAssestIdLoading: false,
  totalContentsCount: 0,
  contentDeleteLoading: false,
  contentFilter: contentFilterInitValue,
  sourceFilter: sourceFilterInitValue,
  adminContentFilter: adminFilterInitValue,
  exportCSV: "",
  isExportCSVLoading: false,
  fileUploadProgress: 0,
  urlUploadProgress: 0,
  downloadLogError: null,
  downloadLimit: 0,
  isFetchDownloadLimit: false,
  isSaveDownloadLimit: false,
  isUserListLoading: false,
  userPageCount: 1,
  isRunVisionLoading: false,
  isProcessStopLoading: false,
  isSummaryGenerationLoading: false,
  relevantAssetView: RelevantAssetView.None,
  isMultipleContentDeleteLoading: false,
  bookmarkedContents: [],
  selectionAppliedForContent: false,
  selectionAppliedForSources: false,
  selectedContentIds: [],
  contentFilesInContext: [],
  isRetryLoading: false,
  allAssetsSelected: false,
  isFileLevelUpdating: false,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllContentDetails: (state, action: PayloadAction<IContent[]>) => {
      if (state.selectedContentIds) {
        const filteredContents = action.payload.map((c) => ({
          ...c,
          isSelected: state.selectedContentIds.includes(c.asset_id),
        }));
        state.selectionAppliedForContent = filteredContents.length ? true : false;
        state.selectionAppliedForSources = filteredContents.length ? true : false;
        state.contents = filteredContents;
      } else state.contents = action.payload;
    },
    setSelectContentDetails: (state, action: PayloadAction<IContent[]>) => {
      state.contents = action.payload;
    },

    updateUploadedContent: (state, action: PayloadAction<IContent>) => {
      state.contents.unshift(action.payload);
    },

    setIsContentTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isContentTableLoading = action.payload;
    },

    setContentById: (state, action: PayloadAction<IContent>) => {
      state.contentById = action.payload;
    },

    setIsContentByIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isContentByIdLoading = action.payload;
    },

    setIsEditContentLoading: (state, action: PayloadAction<boolean>) => {
      state.isEditContentLoading = action.payload;
    },

    removeContributor: (state, action: PayloadAction<number>) => {
      const contributors = state.contentById?.contributors.filter((v) => v.id !== action.payload);
      state.contentById = {
        ...state.contentById,
        contributors,
      };
    },

    setShowMoreSummary: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isShowFullSummary = true;
      state.contents = values;
    },

    setShowMoreTags: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isShowFullTags = true;
      state.contents = values;
    },

    setUpdatePublishDraft: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isPublished = ["TRUE", true].includes(
        values[action.payload].isPublished
      )
        ? false
        : true;
      state.contents = values;
    },

    setSelectedAllTagsByAssestId: (state, action: PayloadAction<ITagsByAssestId>) => {
      state.selectedAllTagByAssestId = action.payload;
    },

    setIsAllTagsByAssestIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isTagsByAssestIdLoading = action.payload;
    },

    setIsUpdateTagsByAssestIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdateTagsByAssestIdLoading = action.payload;
    },

    autoRefreshContent: (_, action: PayloadAction<{ assetId: number }>) => {
      let res: IContent;
      if (action.payload.assetId !== -1) {
        let interval = setInterval(async () => {
          res = await autoRefreshedAssetById(action.payload.assetId);

          if (res?.error) {
            clearInterval(interval);
          } else {
            !!res &&
              res.assetStatus === AssetUploadStatus.LOADED_INTO_LLM &&
              clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.COMPLETED && clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.FAILED && clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.STOPPED && clearInterval(interval);
          }
        }, 5000);
      }
    },

    refreshedAssetByAssetId: (state, action: PayloadAction<{ data: IContent; id: number }>) => {
      state.contents = state.contents.map((v) =>
        v.asset_id === action.payload.id ? action.payload.data : v
      );
      state.contentById = action.payload.data;
    },

    setTotalContentCount: (state, action: PayloadAction<number>) => {
      state.totalContentsCount = action.payload;
    },

    setContentDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.contentDeleteLoading = action.payload;
    },

    setUpdateContentFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | number | null | boolean | any[] }>
    ) => {
      state.contentFilter = {
        ...state.contentFilter,
        [action.payload.field]: action.payload.value,
      };
    },
    setUpdateAllContentFilter: (state, action: PayloadAction<IContentFilter>) => {
      state.contentFilter = action.payload;
    },
    clearContentFilter: (state) => {
      state.contentFilter = contentFilterInitValue;
    },
    clearContentFilterWithoutBookmark: (state) => {
      state.contentFilter = {
        ...contentFilterInitValue,
        isBookmarked: state?.contentFilter?.isBookmarked,
        isFilterVisible: state?.contentFilter?.isFilterVisible,
      };
    },

    setUpdateSourceFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | number | null | boolean }>
    ) => {
      state.sourceFilter = {
        ...state.sourceFilter,
        [action.payload.field]: action.payload.value,
      };
    },
    setUpdateAllSourceFilter: (state, action: PayloadAction<IContentFilter>) => {
      state.sourceFilter = action.payload;
    },
    clearSourceFilter: (state) => {
      state.sourceFilter = sourceFilterInitValue;
    },
    clearSourceFilterWithoutBookmark: (state) => {
      state.sourceFilter = {
        ...sourceFilterInitValue,
        isBookmarked: state?.sourceFilter?.isBookmarked,
        isFilterVisible: state?.sourceFilter?.isFilterVisible,
      };
    },

    setUpdateAdminContentFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | number | null | boolean }>
    ) => {
      state.adminContentFilter = {
        ...state.adminContentFilter,
        [action.payload.field]: action.payload.value,
      };
    },
    setUpdateAllAdminContentFilter: (state, action: PayloadAction<IContentFilter>) => {
      state.adminContentFilter = action.payload;
    },
    clearAdminContentFilter: (state) => {
      state.adminContentFilter = adminFilterInitValue;
    },
    clearAdminContentFilterWithoutBookmark: (state) => {
      state.adminContentFilter = {
        ...adminFilterInitValue,
        isBookmarked: state?.adminContentFilter?.isBookmarked,
        isFilterVisible: state?.adminContentFilter?.isFilterVisible,
      };
    },

    setContentExportCSV: (state, action: PayloadAction<string>) => {
      state.exportCSV = action.payload;
    },

    setIsContentExportCSVLoading: (state, action: PayloadAction<boolean>) => {
      state.isExportCSVLoading = action.payload;
    },

    setFileUploadProgress: (state, action: PayloadAction<number>) => {
      state.fileUploadProgress = action.payload;
    },

    setURLUploadProgress: (state, action: PayloadAction<number>) => {
      state.urlUploadProgress = action.payload;
    },

    setDownloadLogError: (state, action: PayloadAction<string | null>) => {
      state.downloadLogError = action.payload;
    },
    setDownloadLimit: (state, action: PayloadAction<number>) => {
      state.downloadLimit = action.payload;
    },
    setIsFetchDownloadLimit: (state, action: PayloadAction<boolean>) => {
      state.isFetchDownloadLimit = action.payload;
    },
    setIsSaveDownloadLimit: (state, action: PayloadAction<boolean>) => {
      state.isSaveDownloadLimit = action.payload;
    },
    setIsUserListLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserListLoading = action.payload;
    },
    setUserListPageCount: (state, action: PayloadAction<number>) => {
      state.userPageCount = action.payload;
    },

    setIsRunVisionLoading: (state, action: PayloadAction<boolean>) => {
      state.isRunVisionLoading = action.payload;
    },
    updateAssetByAssetId: (state, action: PayloadAction<{ data: IContent; id: number }>) => {
      state.contents = state.contents.map((v) =>
        v.asset_id === action.payload.id ? action.payload.data : v
      );
    },
    setIsProcessStopLoading: (state, action: PayloadAction<boolean>) => {
      state.isProcessStopLoading = action.payload;
    },

    setIsSummaryGenerationLoading: (state, action: PayloadAction<boolean>) => {
      state.isSummaryGenerationLoading = action.payload;
    },

    updatePageOnContentDelete: (state, action: PayloadAction<number>) => {
      state.contentFilter.currentPage = action.payload;
    },
    updatePageOnSourceDelete: (state, action: PayloadAction<number>) => {
      state.sourceFilter.currentPage = action.payload;
    },

    setRelevantAssetView: (state, action: PayloadAction<RelevantAssetView>) => {
      state.relevantAssetView = action.payload;
    },

    setIsMultipleContentDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.isMultipleContentDeleteLoading = action.payload;
    },

    setBookmarkedContents: (state, action: PayloadAction<IContent[]>) => {
      state.bookmarkedContents = action.payload;
    },
    setSelectionAppliedForContent: (state, action: PayloadAction<boolean>) => {
      state.selectionAppliedForContent = action.payload;
    },
    setContentSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedContentIds = action.payload;
      const filteredContents = state.contents.map((c) => ({
        ...c,
        isSelected: action.payload.includes(c.asset_id),
      }));
      state.contents = filteredContents;
    },
    setSelectionAppliedForSources: (state, action: PayloadAction<boolean>) => {
      state.selectionAppliedForSources = action.payload;
    },
    setContentFilesInContext: (state, action: PayloadAction<IFileINContext[]>) => {
      state.contentFilesInContext = action.payload;
    },
    setIsRetryLoading: (state, action: PayloadAction<boolean>) => {
      state.isRetryLoading = action.payload;
    },

    setAllAssetsSelected: (state, action: PayloadAction<boolean>) => {
      state.allAssetsSelected = action.payload;
    },

    setIsFileLevelUpdating: (state, action: PayloadAction<boolean>) => {
      state.isFileLevelUpdating = action.payload;
    },
  },
});

export const {
  setContentById,
  setShowMoreTags,
  removeContributor,
  setShowMoreSummary,
  autoRefreshContent,
  setAllContentDetails,
  setUpdatePublishDraft,
  updateUploadedContent,
  setIsEditContentLoading,
  setIsContentByIdLoading,
  refreshedAssetByAssetId,
  setIsContentTableLoading,
  setSelectedAllTagsByAssestId,
  setIsAllTagsByAssestIdLoading,
  setIsUpdateTagsByAssestIdLoading,
  setTotalContentCount,
  setContentDeleteLoading,
  setUpdateContentFilter,
  setUpdateAllContentFilter,
  clearContentFilter,
  clearContentFilterWithoutBookmark,
  setUpdateSourceFilter,
  setUpdateAllSourceFilter,
  clearSourceFilter,
  clearSourceFilterWithoutBookmark,
  setUpdateAdminContentFilter,
  clearAdminContentFilterWithoutBookmark,
  clearAdminContentFilter,
  setUpdateAllAdminContentFilter,
  setContentExportCSV,
  setIsContentExportCSVLoading,
  setFileUploadProgress,
  setURLUploadProgress,
  setDownloadLogError,
  setDownloadLimit,
  setIsFetchDownloadLimit,
  setIsSaveDownloadLimit,
  setIsUserListLoading,
  setUserListPageCount,
  setIsRunVisionLoading,
  updateAssetByAssetId,
  setIsProcessStopLoading,
  setIsSummaryGenerationLoading,
  updatePageOnContentDelete,
  setRelevantAssetView,
  setIsMultipleContentDeleteLoading,
  setBookmarkedContents,
  setSelectionAppliedForContent,
  setSelectionAppliedForSources,
  setContentSelectedIds,
  setSelectContentDetails,
  setContentFilesInContext,
  updatePageOnSourceDelete,
  setIsRetryLoading,
  setAllAssetsSelected,
  setIsFileLevelUpdating,
} = userSlice.actions;

export default userSlice.reducer;
