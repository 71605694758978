import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, Input, MenuProps, Modal } from "antd";
import "./historyPanel.scss";
import { RootState } from "../../../store";
import { setProfileResume } from "../../../store/profile/profileSlice";
import { TFiltersType } from "../../../store/filtersFromTaxonomyData/filters.interface";
import {
  convertToString,
  groupDataByDate,
  GroupedData,
  IMAGES,
  isEmpty,
  useChats,
} from "../../../shared";
import {
  deleteChatHistory,
  editChatTitle,
  getAllChatHistory,
} from "../../../services/conversationalSearch";
import {
  setFilterDateRange,
  setFiltersData,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import {
  setChatHistory,
  setIsOpenChatHistory,
  setSelectedChatHistory,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import {
  IChatHistory,
  ITags,
} from "../../../store/conversationalSearch/conversationalSearch.interface";

const HistoryPanel = () => {
  const [historyTitle, setHistoryTitle] = useState("");
  const [activeRowId, setActiveRowId] = useState<number | null>(null);
  const [editChat, setEditChat] = useState<IChatHistory | null>(null);
  const [filteredChatHistory, setFilteredChatHistory] = useState<IChatHistory[]>([]);

  const { userId } = useSelector((state: RootState) => state.auth);
  const { findProfile } = useSelector((state: RootState) => state.app);
  const { chatHistory, selectedChatHistory } = useSelector(
    (state: RootState) => state.conversationalSearch
  );

  const dispatch = useDispatch();
  const [modal, contextHolder] = Modal.useModal();
  const { clearAllChatStates, defaultConfigureState } = useChats();

  useEffect(() => {
    if (findProfile) {
      setFilteredChatHistory(chatHistory.filter((v) => v?.resumeFinder));
    } else setFilteredChatHistory(chatHistory.filter((v) => !v?.resumeFinder));
  }, [chatHistory, findProfile]);

  const handleDelete = async (history: IChatHistory) => {
    const res = await deleteChatHistory(history.id);

    if (!isEmpty(res?.data)) {
      getAllChatHistory({ userId: userId, configSettingId: 1 });
      if (history.id === selectedChatHistory.id) {
        clearAllChatStates();
        defaultConfigureState();
      }
    }
  };

  const updateHistoryTitle = async (index: number) => {
    setEditChat(null);
    const res = await editChatTitle({ id: editChat?.id || 0, title: historyTitle });

    if (!isEmpty(res?.data)) {
      if (index >= 0) {
        let values: any[] = [...filteredChatHistory];
        values[index] = {
          ...editChat,
          title: historyTitle,
        };
        dispatch(setChatHistory(values));
      }
    }
  };

  const handleSelectChatHistory = (chatHistory: IChatHistory) => {
    const lastProfileHistory =
      chatHistory && chatHistory.messages[chatHistory.messages.length - 1].profileDetails;
    console.log(lastProfileHistory, chatHistory);
    findProfile && lastProfileHistory && dispatch(setProfileResume(lastProfileHistory));

    dispatch(setSelectedChatHistory(chatHistory));
    const { chatTaxonomies } = chatHistory;
    const selectedTaxonomies = chatTaxonomies[0];

    const industryFilter = selectedTaxonomies?.tags?.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.Industry
    );
    const functionFilter = selectedTaxonomies?.tags?.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.Functions
    );
    const assetTypeFilter = selectedTaxonomies?.tags?.find(
      (filter: ITags) => filter.taxonomyName === TFiltersType.AssetType
    );

    dispatch(
      setFiltersData([
        {
          type: TFiltersType.Industry,
          data: convertToString(industryFilter?.taxonomyNodeIds || []) || [],
        },
        {
          type: TFiltersType.Functions,
          data: convertToString(functionFilter?.taxonomyNodeIds || []) || [],
        },
        {
          type: TFiltersType.AssetType,
          data: convertToString(assetTypeFilter?.taxonomyNodeIds || []) || [],
        },
        { type: TFiltersType.FileType, data: selectedTaxonomies?.fileType },
      ])
    );
    dispatch(setFilterDateRange([selectedTaxonomies?.startDate, selectedTaxonomies?.endDate]));
    dispatch(setIsOpenChatHistory(false));
  };

  const reframeText = (title: string) => {
    return title?.replace(/\\/g, "")?.replace(/"/g, "");
  };

  let groupedData: GroupedData = groupDataByDate(filteredChatHistory);

  return (
    <div className="history-body-content body-scroll">
      {contextHolder}
      <div hidden={!groupedData || !Object.keys(groupedData).length} className="history-list">
        {Object.keys(groupedData)?.map((key: string, index: number) => (
          <div className="history-item" key={`${key}-${index}`}>
            <h5 className="date-time">{key}</h5>
            <ul>
              {groupedData[key as keyof GroupedData]?.map(
                (history: IChatHistory, index: number) => {
                  const items: MenuProps["items"] = [
                    {
                      key: "rename",
                      icon: <i className="ri-edit-2-line"></i>,
                      label: <span>Rename</span>,
                      onClick: () => [
                        setHistoryTitle(reframeText(history.title)),
                        setEditChat(history),
                      ],
                    },
                    {
                      key: "delete",
                      icon: <i className="ri-delete-bin-line"></i>,
                      label: <span>Delete</span>,
                      onClick: () =>
                        modal.confirm({
                          className: "modal-confirm-ui",
                          title: "Are you sure you want to delete chat?",
                          centered: true,
                          closable: true,
                          // content: "Are you sure?",
                          okText: "Delete",
                          onOk: () => handleDelete(history),
                          cancelText: "Cancel",
                        }),
                    },
                  ];
                  return (
                    <li
                      key={index}
                      className={`d-flex d-flex-middle ${
                        editChat?.id === history.id ? "edit-history-warp" : ""
                      } ${
                        activeRowId === history.id || selectedChatHistory.id === history.id
                          ? "active"
                          : ""
                      }`}>
                      {editChat?.id === history.id ? (
                        <>
                          <i className="ri-message-2-line"></i>
                          <span className="history-item-content edit-input-wrap">
                            <div className="edit-input position-relative">
                              <Input
                                className="form-control"
                                value={historyTitle}
                                onChange={(e) => setHistoryTitle(e.target.value)}
                                onPressEnter={() => updateHistoryTitle(index)}
                              />
                              <div className="edit-btn position-absolute">
                                <i
                                  className="active ri-check-line"
                                  onClick={() => updateHistoryTitle(index)}></i>
                                <i
                                  className="ri-close-line"
                                  onClick={() => [setEditChat(null), setHistoryTitle("")]}></i>
                              </div>
                            </div>
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="ri-message-2-line"></i>
                          <span
                            className="history-item-content text-overflow"
                            onClick={() => handleSelectChatHistory(history)}>
                            {reframeText(history.title) || "File Upload"}
                          </span>
                          <Dropdown
                            onOpenChange={(e) => setActiveRowId(e ? history.id : null)}
                            trigger={["click"]}
                            menu={{
                              items,
                            }}
                            placement="bottomRight"
                            overlayClassName="ui-dropdown-action">
                            <Button type="link" className="action-more">
                              <i className="ri-more-2-fill"></i>
                            </Button>
                          </Dropdown>
                        </>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          </div>
        ))}

        {/* </div> */}
        {/* <div className="history-item">
          <h5 className="date-time">Previous 30 days</h5>
          <ul>
            {[...Array(3)].map((_, index) => (
              <li key={index} className="d-flex d-flex-middle">
                <i className="ri-message-2-line"></i>
                <span className="history-item-content text-overflow">
                  Lorem Ipsum is simply dummy text of the printing printing
                </span>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                    // onClick: ({ key }) => {
                    //   handleChatHistoryAction(key, index);
                    // },
                  }}
                  placement="bottomRight"
                  overlayClassName="ui-dropdown-action">
                  <button type="button" className="action-more">
                    <i className="ri-more-2-fill"></i>
                  </button>
                </Dropdown>
              </li>
            ))}
          </ul>
        </div>
        <div className="history-item">
          <h5 className="date-time">March</h5>
          <ul>
            {[...Array(3)].map((_, index) => (
              <li key={index} className="d-flex d-flex-middle">
                <i className="ri-message-2-line"></i>
                <span className="history-item-content text-overflow">
                  Lorem Ipsum is simply dummy text of the printing printing
                </span>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items,
                    // onClick: ({ key }) => {
                    //   handleChatHistoryAction(key, index);
                    // },
                  }}
                  placement="bottomRight"
                  overlayClassName="ui-dropdown-action">
                  <button type="button" className="action-more">
                    <i className="ri-more-2-fill"></i>
                  </button>
                </Dropdown>
              </li>
            ))}
          </ul>
        </div>*/}
      </div>
      {!groupedData || !Object.keys(groupedData).length ? (
        <div className="history-empty-box">
          <div className="empty-wrap">
            <img src={IMAGES.exptyBox} alt="empty-data" />
            <span>No Data Found</span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default HistoryPanel;
