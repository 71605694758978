import { Outlet } from "react-router-dom";
import { SideBar } from "../../components";
import "./AdminLayout.scss";
import { PATHS } from "../../shared";
import { MenuProps } from "antd";
type MenuItem = Required<MenuProps>["items"][number];

const AdminLayout = () => {
  const sideBarItems: MenuItem[] = [
    {
      label: "Access Group",
      icon: <i className="ci ci-access-group-icon" />,
      key: PATHS.adminAccessGroup,
    },
    {
      label: "User Management",
      icon: <i className="ci ci-user-management-icon" />,
      key: PATHS.userManagement,
    },
    {
      label: "Asset Management",
      icon: <i className="ci ci-asset-management-icon" />,
      key: PATHS.adminContentManagement,
    },
    {
      label: "Data Connection",
      icon: <i className="ci ci-data-connection-icon" />,
      key: PATHS.adminDataConnection,
    },
    {
      label: "Taxonomy",
      icon: <i className="ci ci-taxonomy-icon" />,
      key: PATHS.adminTaxonomyManagement,
    },
    // {
    //   label: "Workflow Manager",
    //   icon: <img src={IMAGES.workflowManagerIcon} />,
    //   key: PATHS.adminWorkflowManager,
    // },
    {
      label: "Theme",
      icon: <i className="ci ci-theme-icon" />,
      key: PATHS.adminTheme,
    },
  ];

  return (
    <div className="adminLayoutContainer">
      <div className={"side-bar-outlet-wrap sidebar-with-content sidebar-with-user-content"}>
        <SideBar {...{ sideBarItems }} />
        <div className="outlet-wrap">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
