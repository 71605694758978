import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { toast } from "react-toastify";
import { RootState } from "../../store";
import { setContentSelectedIds } from "../../store/contentManagement/contentSlice";
import {
  setContentFilterForChat,
  setContentFilterForNewChat,
  setIsChatStartWithAssetFilter,
  setIsNewChatStartWithContentFilter,
  setIsNewContextFilterApplied,
  setSelectedFilesForAssetFilter,
  setSelectedReleventAsset,
  setStartNewChatFromInsight,
} from "../../store/conversationalSearch/conversationalSearchSlice";
import { setAppLayoutview } from "../../store/appState/appSlice";
import { IContent, IContentFilter } from "../../store/contentManagement/content.interface";
import {
  ICitations,
  IFileINContext,
} from "../../store/conversationalSearch/conversationalSearch.interface";
import { setIsImageVisible, setIsPDFModalOpen } from "../../store/pdfViewer/pdfViewer.slice";
import { AppLayout, FromWhere, isAssetPublished, isEmpty } from "../../shared";
import {
  isMyContentChatFilterEmpty,
  isSourcesChatFilterEmpty,
} from "../../shared/helper/contentHelper";
import "../contentActionButton/contentActionButton.scss";

interface IContentActionButton {
  localFilters?: IContentFilter;
  content?: IContent;
  selectedContents?: IContent[] | ICitations[];
  isVisible?: boolean;
  isDisabled?: boolean;
  fromWhere?: string;
}

const ContentChatButton = ({
  localFilters,
  content,
  selectedContents,
  isVisible = false,
  isDisabled = false,
  fromWhere = "",
}: IContentActionButton) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { startNewChatFromInsight, selectedChatHistory } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  const { contents } = useSelector((state: RootState) => state.content);

  const handleChatWithAssetFilter = () => {
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    dispatch(setSelectedReleventAsset([]));
    const fileInContext: IFileINContext[] =
      content && !isEmpty(content)
        ? [
            {
              asset_id: content.asset_id,
              asset_type: "",
              file_name: content.file_name,
              file_type: content.file_type || "",
              title: content.title,
              isPublished: content.isPublished,
            },
          ]
        : !isEmpty(selectedContents)
        ? selectedContents?.map((co) => ({
            asset_id: co.asset_id,
            asset_type: "",
            file_name: co.file_name,
            file_type: co.file_type || "",
            title: co.title,
            isPublished: co?.isPublished ? co.isPublished : "TRUE",
          })) || []
        : [];

    dispatch(setSelectedFilesForAssetFilter(fileInContext));
    dispatch(setContentSelectedIds(fileInContext.map((e) => e.asset_id)));
    toast.success("Chat context has changed");
  };

  const handleExistingChat = () => {
    const isSelectedContentsPublished = getIsSelectedContentsPublished(selectedContents || []);
    if (!isSelectedContentsPublished) {
      if (selectedContents && selectedContents?.length > 1) {
        toast.error("Some files are not published");
      } else {
        toast.error("File is not published");
      }
      return;
    }

    if (selectedChatHistory.id === -1) startNewChat();
    if (content || !isEmpty(selectedContents)) {
      handleChatWithAssetFilter();
      dispatch(setContentFilterForChat({ pageLocation: location.pathname }));
      dispatch(setIsNewContextFilterApplied(true));
    } else {
      dispatch(
        setSelectedFilesForAssetFilter(
          contents
            .filter((e) => e.isPublished === "TRUE")
            .map((co) => ({
              asset_id: co.asset_id,
              asset_type: "",
              file_name: co.file_name,
              file_type: co.file_type || "",
              title: co.title,
              isPublished: co?.isPublished ? co.isPublished : "TRUE",
            }))
        )
      );
      dispatch(setContentSelectedIds([]));
      dispatch(setAppLayoutview(AppLayout.MINIMIZE));
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForChat({ ...localFilters, pageLocation: location.pathname }));
    }
    dispatch(setIsPDFModalOpen(false));
    dispatch(setIsImageVisible(false));
  };

  const getIsSelectedContentsPublished = (selectedContents: IContent[] | ICitations[]) => {
    let isPublished = true;

    // set isPublished = true in case of Relevant asset and Find profile
    if ([String(FromWhere.RelevantAsset), FromWhere.FindProfile].includes(fromWhere)) {
      isPublished = true;
    } else if (!isEmpty(content) && !isAssetPublished(content)) {
      isPublished = false;
    } else {
      for (let contentObj of selectedContents) {
        if (!isAssetPublished(contentObj)) {
          isPublished = false;
          break;
        }
      }
    }

    return isPublished;
  };

  const startNewChat = () => {
    const isSelectedContentsPublished = getIsSelectedContentsPublished(selectedContents || []);
    if (!isSelectedContentsPublished) {
      if (selectedContents && selectedContents?.length > 1) {
        toast.error("Some files are not published");
      } else {
        toast.error("File is not published");
      }
      return;
    }

    dispatch(setStartNewChatFromInsight(true));
    if (content || !isEmpty(selectedContents)) {
      dispatch(setIsChatStartWithAssetFilter(false));
      handleChatWithAssetFilter();
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForNewChat({ pageLocation: location.pathname }));
      dispatch(setIsNewChatStartWithContentFilter(true));
    } else {
      dispatch(setSelectedFilesForAssetFilter([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setAppLayoutview(AppLayout.MINIMIZE));
      dispatch(setIsNewContextFilterApplied(true));
      dispatch(setContentFilterForNewChat({ ...localFilters, pageLocation: location.pathname }));
      dispatch(setIsNewChatStartWithContentFilter(true));
    }
    dispatch(setIsPDFModalOpen(false));
    dispatch(setIsImageVisible(false));
  };

  const getItems = () => {
    let items: MenuProps["items"] = [];

    items.push(
      {
        key: "1",
        label: "Existing Chat",
        onClick: handleExistingChat,
      },
      {
        key: "2",
        label: "New Chat",
        onClick: startNewChat,
      }
    );

    return items;
  };

  const isChatBtnVisible = () => {
    return (
      (localFilters &&
        (!isMyContentChatFilterEmpty(localFilters) || !isSourcesChatFilterEmpty(localFilters))) ||
      !isEmpty(content) ||
      isVisible ||
      !isEmpty(selectedContents)
    );
  };

  const isChatBtnDisable = (): boolean =>
    !isEmpty(content) && !isAssetPublished(content) && fromWhere !== FromWhere.RelevantAsset;

  const isChatIconDisable = (): boolean =>
    !isAssetPublished(content || null) && [String(FromWhere.MyContent)].includes(fromWhere);

  return (
    <>
      {isChatBtnVisible() ? (
        <>
          {startNewChatFromInsight || selectedChatHistory?.id === -1 ? (
            <div className={`chat-document-wrap ${isChatIconDisable() && "chat-icon-disable"}`}>
              <Tooltip
                placement="top"
                title="Chat with the asset"
                trigger="hover"
                overlayClassName="tooltip-text">
                <Button
                  type="primary"
                  className={`btn chat-document`}
                  onClick={startNewChat}
                  disabled={isChatBtnDisable()}
                  icon={<i className="ci-small-chat-icon ci"></i>}>
                  Chat
                  {/* <i className="ri-arrow-down-s-line"></i> */}
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div className={`chat-document-wrap ${isChatIconDisable() && "chat-icon-disable"}`}>
              <Dropdown
                trigger={["click"]}
                disabled={isDisabled}
                menu={{
                  items: getItems(),
                }}
                overlayClassName="table-action-dropdown content-dropdown"
                placement="bottomRight">
                <Tooltip
                  placement="top"
                  title="Chat with the asset"
                  trigger="hover"
                  overlayClassName="tooltip-text">
                  <Button
                    type="primary"
                    className={`btn chat-document`}
                    icon={<i className="ci-small-chat-icon ci"></i>}>
                    Chat
                    {/* <i className="ri-arrow-down-s-line"></i> */}
                  </Button>
                </Tooltip>
              </Dropdown>
            </div>
          )}

          {/* <Tooltip placement="topRight">
              <Button
                onClick={handleChatWithAssetFilter}
                type="primary"
                className="btn chat-document"
                icon={<i className="ci-small-chat-icon ci"></i>}>
                Chat
              </Button>
            </Tooltip> */}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default ContentChatButton;
