import { toast } from "react-toastify";
import { store } from "../store";
import { get, post, put } from "./apiClients";
import {
  setAllUsersDetails,
  setUserTableLoading,
  setUserDeleteLoading,
  setTotalUsersCount,
  setUserCurrPage,
  setConfigurationList,
  setFileUploadProgress,
  setIsFileUpdating,
  setIsGetProfileLoading,
  setIsProfileUpdating,
  setIsResumeUploading,
  setUserProfile,
  userProfileInitValue,
} from "../store/userManagement/usersSlice";
import { isEmpty, PageLimit, UploadResumeState } from "../shared";

interface IAddUserRequest {
  name: string;
  email: string;
  password: string;
  userPermission: [
    {
      roleName: string | undefined;
      configSettingId: number;
    }
  ];
  accessGroups: {
    accessGroupId: number;
  }[];
}

export const fetchAllUsers = async (searchText = "", page = 1) => {
  try {
    store.dispatch(setUserTableLoading(true));
    const res = await get(
      `core/token/user-taxonomy?nameOrEmail=${searchText}&page=${page - 1}&limit=${
        PageLimit.UserManagement
      }`
    );

    const userRes = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;
    const currPageRes = res.number ? res.number + 1 : 1;

    store.dispatch(setAllUsersDetails(userRes));
    store.dispatch(setTotalUsersCount(totalElementsRes));
    store.dispatch(setUserCurrPage(currPageRes));
    return userRes;
  } catch (error) {
    store.dispatch(setAllUsersDetails([]));
    console.log(error);
  } finally {
    store.dispatch(setUserTableLoading(false));
  }
};

export const addUser = async (
  request: IAddUserRequest,
  searchText: string,
  currPage: number,
  taxonomyNodes: any,
  isTaxonomyMappingChanged: boolean
) => {
  try {
    const res = await post(`/core/token/adduser`, request);
    if (isTaxonomyMappingChanged) {
      await updateUserTaxonomy(res.data.id, taxonomyNodes);
    }
    await fetchAllUsers(searchText, currPage);
    toast.success("User added successfully");
    return res;
  } catch (error: any) {
    console.log("Error while add user", error);
    if (typeof error === "string") {
      toast.error(error ?? "Failed to add user");
    } else {
      toast.error("Failed to add user");
    }
  }
};

export const updateUser = async (
  request: any,
  searchText: string,
  currPage: number,
  taxonomyNodes: any,
  isFormValueChanged: boolean,
  isTaxonomyMappingChanged: boolean
) => {
  try {
    if (isFormValueChanged && isTaxonomyMappingChanged) {
      const res = await post(`/core/token/editUser`, request);
      await updateUserTaxonomy(request?.userId, taxonomyNodes);
      await fetchAllUsers(searchText, currPage);
      toast.success("User updated successfully");
      return res;
    } else if (isFormValueChanged) {
      const res = await post(`/core/token/editUser`, request);
      await fetchAllUsers(searchText, currPage);
      toast.success("User updated successfully");
      return res;
    } else if (isTaxonomyMappingChanged) {
      await updateUserTaxonomy(request?.userId, taxonomyNodes);
      await fetchAllUsers(searchText, currPage);
      toast.success("Tags updated successfully");
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteUser = async (id: number, searchText: string, currPage: number) => {
  store.dispatch(setUserDeleteLoading(true));
  try {
    const res = await get(`/core/token/remove/${id}`);
    await fetchAllUsers(searchText, currPage);
    toast.success("User deleted successfully");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("User failed to delete");
    return error;
  } finally {
    store.dispatch(setUserDeleteLoading(false));
  }
};

export const updateUserStatus = async (
  typeofStatus: string,
  userId: number | undefined,
  searchText: string,
  userCurrPage: number
) => {
  try {
    const res = await get(`/core/token/${typeofStatus}/${userId}`);
    await fetchAllUsers(searchText, userCurrPage);
    toast.success("Status updated successfully");
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//

export const updateUserTaxonomy = async (
  userId: number,
  taxonomyMapping: [{ taxonomyId: number; taxonomyNodeIds: [number] }]
) => {
  try {
    const res = await post(`/core/user-taxonomy`, { userId, ...taxonomyMapping });
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add taxonomy nodes");
    return error;
  }
};

///core/configuration-settings/
export const getConfigurationList = async () => {
  try {
    const res = await get(`/core/configuration-settings/`);
    store.dispatch(setConfigurationList(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadProfilePic = async (
  formData: FormData,
  setUploadingState: Function,
  isBackground = false
) => {
  try {
    setUploadingState(UploadResumeState.UPLOADING);
    store.dispatch(setIsFileUpdating(true));
    const { data } = await put(`/core/profile/picture`, formData, {
      params: {},
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setUploadingState(UploadResumeState.UPLOADED);
    fetchUserProfile();
    if (isBackground) {
      toast.success("Background cover successfully updated");
    } else {
      toast.success("Profile pic successfully updated");
    }

    return data;
  } catch (error: any) {
    setUploadingState(UploadResumeState.FAILED);
    if (isBackground) {
      toast.error(error?.errorMessage ?? "Failed to update background cover");
    } else {
      toast.error(error?.errorMessage ?? "Failed to update profile pic");
    }
  } finally {
    store.dispatch(setIsFileUpdating(false));
  }
};

export const uploadProfileResume = async (formData: FormData, setUploadingState: Function) => {
  try {
    setUploadingState(UploadResumeState.UPLOADING);
    store.dispatch(setIsResumeUploading(true));
    const { data } = await post(`/core/profile/resume`, formData, {
      params: {},
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setUploadingState(UploadResumeState.UPLOADED);
    fetchUserProfile();
    toast.success("Resume successfully uploaded");

    return data;
  } catch (error: any) {
    setUploadingState(UploadResumeState.FAILED);
    toast.error(error?.errorMessage ?? "Failed to upload resume");
  } finally {
    store.dispatch(setIsResumeUploading(false));
  }
};

export const fetchUserProfile = async () => {
  try {
    store.dispatch(setIsGetProfileLoading(true));
    const res = await get(`core/profile`);
    if (!isEmpty(res)) {
      store.dispatch(setUserProfile(res));
    }
    return res;
  } catch (error) {
    console.log(error);
    store.dispatch(setUserProfile(userProfileInitValue));
  } finally {
    store.dispatch(setIsGetProfileLoading(false));
  }
};

export const userProfilePulling = async () => {
  try {
    const res = await get(`core/profile`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const extractResumeDetails = async () => {
  try {
    const { data } = await put(`core/profile/extract`);
    return data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to extract details from resume");
  }
};

export const updateUserProfile = async (profileDetails: any) => {
  try {
    store.dispatch(setIsProfileUpdating(true));
    const res = await post(`/core/profile`, { ...profileDetails });
    fetchUserProfile();
    toast.success("Profile successfully updated");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to update profile");
  } finally {
    store.dispatch(setIsProfileUpdating(false));
  }
};
