import { FC, useState } from "react";
import { Button, Image, Space } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { ContentChatButton } from "../../../components";
import { RootState } from "../../../store";
import { setIsImageVisible } from "../../../store/pdfViewer/pdfViewer.slice";
import { isEmpty } from "../../helpers";
import { AssetUploadStatus, FromWhere } from "../../constants";
import "./ImageViewer.scss";

interface ImageViewerProps {
  handleDetail?: () => void;
  fromWhere?: string;
}

const ImageViewer: FC<ImageViewerProps> = ({ handleDetail, fromWhere = "" }) => {
  const dispatch = useDispatch();
  const { isImageVisible, imageURL, pdfContent } = useSelector(
    (state: RootState) => state.pdfViewer
  );
  const [scaleStep] = useState(0.5);

  const isViewDetailsBtnDisable = () => {
    let isDisable = false;
    if ([String(FromWhere.RelevantAsset)].includes(fromWhere || "")) {
      isDisable = false;
    } else if (!isEmpty(pdfContent) && pdfContent?.assetStatus !== AssetUploadStatus.COMPLETED) {
      isDisable = true;
    }

    return isDisable;
  };

  return isImageVisible ? (
    <div style={{ display: "none" }}>
      <Image
        preview={{
          visible: true,
          scaleStep,
          src: imageURL,
          onVisibleChange: (value) => {
            dispatch(setIsImageVisible(value));
          },
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
            }
          ) => (
            <Space size={12} className="ant-image-preview-operations custom-toolbar-image">
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              <div className="image-viewer-extra">
                {fromWhere !== FromWhere.AdminContentManagement && (
                  <ContentChatButton isVisible={true} content={pdfContent} />
                )}
                {handleDetail && (
                  <Button
                    type="default"
                    className="btn view-asset-btn"
                    disabled={isViewDetailsBtnDisable()}
                    onClick={() => handleDetail()}>
                    View Detail
                  </Button>
                )}
              </div>
            </Space>
          ),
        }}
      />
    </div>
  ) : (
    ""
  );
};

export default ImageViewer;
